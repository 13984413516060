import React, { useEffect, useState, ChangeEvent, DragEvent } from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import {
	DrawerBody,
	DrawerHeader,
	DrawerHeaderTitle,
	Drawer,
	Dialog,
	DialogSurface,
	DialogBody,
	DialogContent,
	DialogActions,
	Field,
	Button,
	Spinner,
	Menu,
	MenuTrigger,
	MenuList,
	MenuItem,
	MenuPopover,
	Tooltip,
} from "@fluentui/react-components";
import {
	bundleIcon,
	Dismiss24Regular,
	PersonRegular,
	PersonFilled,
	SignOutRegular,
	SignOutFilled,
} from "@fluentui/react-icons";
import { msalConfig } from "../auth";
import { getRolePermissions } from "../permissions/rolePermissions";

const Topbar = () => {
	const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
		useBoolean(false);
	const [uploadErrorValidationMsg, setUploadErrorValidationMsg] =
		useState<any>(null);
	const [uploadSuccess, setUploadSuccess] = useState(false);
	const [isUploadInProgress, setIsUploadInProgress] = useState<any>(false);
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [isOpen, setIsOpen] = useState(false);
	const buttonId = useId("callout-button");
	const { pathname } = useLocation();
	const logoutRedirectUri = msalConfig.auth.postLogoutRedirectUri;
	const SignIn = bundleIcon(PersonFilled, PersonRegular);
	const SignOut = bundleIcon(SignOutFilled, SignOutRegular);
	const { instance } = useMsal();
	let activeAccount;

	let userRoles: string[] = [];
	if (instance) {
		activeAccount = instance.getActiveAccount();
		if (activeAccount?.idTokenClaims?.roles) {
			userRoles = activeAccount.idTokenClaims.roles as string[];
		}
	}
	console.log("activeAccount", activeAccount);
	console.log("userRoles", userRoles);

	const { showUploadButton, showEditDetailsButton, showDeleteCandidateButton } =
		getRolePermissions(activeAccount);

	function signInClickHandler(instance: any) {
		instance.loginRedirect();
	}

	function signOutClickHandler(instance: any) {
		const logoutRequest = {
			account: instance.getActiveAccount(),
			postLogoutRedirectUri: `${logoutRedirectUri}`,
		};
		instance.logoutRedirect(logoutRequest);
	}

	function SignInButton() {
		const { instance } = useMsal();
		return (
			<Button onClick={() => signInClickHandler(instance)} icon={<SignIn />}>
				Sign In
			</Button>
		);
	}

	const resetForm = () => {
		setSelectedFiles([]);
		setIsUploadInProgress(false);
		setUploadErrorValidationMsg(null);
	};

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files) {
			const filesArray = Array.from(files);
			setSelectedFiles(filesArray);
		}
	};

	const handleUploadClick = async () => {
		const formData = new FormData();
		selectedFiles.forEach((file) => {
			formData.append("files", file);
		});
		console.log("selected files", selectedFiles);
		try {
			setIsUploadInProgress(true);
			const response = await axios.post(`/candidates/upload`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			console.log(response);
			if (response.status === 200 || response.status === 202) {
				setUploadSuccess(true);
				setIsUploadInProgress(false);
				resetForm();
				setUploadErrorValidationMsg(null);
			} else {
				const error = response.data.message || `HTTP Error ${response.status}`;
				console.error(error);
				setIsUploadInProgress(false);
				resetForm();
				setUploadErrorValidationMsg(error);
				return Promise.reject(error);
			}
		} catch (error) {
			console.error(error);
			setIsUploadInProgress(false);
		}
	};

	const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
		event.dataTransfer.dropEffect = "copy";
	};

	const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
		event.currentTarget.classList.add("drag-over");
	};

	const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
		event.currentTarget.classList.remove("drag-over");
	};

	const handleDrop = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
		event.currentTarget.classList.remove("drag-over");

		const files = event.dataTransfer.files;
		if (files && files.length > 0) {
			const filesArray = Array.from(files);
			setSelectedFiles(filesArray);
		}
	};

	const handleClick = () => {
		fileInputRef?.click();
	};

	let fileInputRef: HTMLInputElement | null = null;

	useEffect(() => {
		if (!isOpen) {
			resetForm();
		}
	}, [isOpen]);

	return (
		<div className="top-navbar">
			<Stack
				horizontal
				verticalAlign="center"
				tokens={{ childrenGap: 20 }}
				className="navbar-stack"
			>
				<Link to="/">
					<div className="logo-container">
						<img src="JardinesLogoWithName.svg" alt="App-logo" />
					</div>
				</Link>
				<Stack
					horizontal
					tokens={{ childrenGap: 20 }}
					className="nav-link-items"
				>
					<Link
						to="candidates"
						className={pathname === "/candidates" ? "active" : ""}
					>
						Candidates
					</Link>
					<Link
						to="/jobopenings"
						className={pathname === "/jobopenings" ? "active" : ""}
					>
						Job openings
					</Link>
					<Link
						to="/topmatchesreport"
						className={pathname === "/topmatchesreport" ? "active" : ""}
					>
						Top Matches Report
					</Link>
					<div>
						{showUploadButton && (
							<Button appearance="primary" onClick={() => setIsOpen(!isOpen)}>
								Upload CV
							</Button>
						)}
						<Drawer
							type="overlay"
							separator
							open={isOpen}
							onOpenChange={(_, { open }) => setIsOpen(open)}
							position="end"
							size="medium"
						>
							<DrawerHeader>
								<DrawerHeaderTitle
									action={
										<Button
											appearance="subtle"
											aria-label="Close"
											icon={<Dismiss24Regular />}
											onClick={() => setIsOpen(false)}
										/>
									}
								>
									Upload Candidate CV
								</DrawerHeaderTitle>
							</DrawerHeader>
							<DrawerBody>
								<div className="upload-container">
									<div>
										<Field
											label=""
											validationMessage={uploadErrorValidationMsg}
										>
											<div
												className="CV-drop-zone"
												onDrop={handleDrop}
												onDragOver={handleDragOver}
												onDragEnter={handleDragEnter}
												onDragLeave={handleDragLeave}
												onClick={handleClick}
											>
												<div
													style={{
														borderRadius: "16px",
														backgroundColor: "#fff",
														padding: "24px",
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														cursor: "pointer",
													}}
												>
													<div
														style={{
															borderRadius: "12px",
															border: "3px dotted var(--color-link)",
															padding: "60px 32px",
															textAlign: "center",
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
															justifyContent: "center",
															width: "100%",
														}}
													>
														<img
															src="Upload.svg"
															alt="Drag and Drop to Upload CV(s)"
															width={72}
															style={{ opacity: "85%" }}
														/>
														<p
															style={{
																marginTop: "16px",
																fontSize: "16px",
																color: "#666",
															}}
														>
															Drag and drop or Click here to upload CV
														</p>
													</div>
												</div>
												<input
													multiple
													id="fileInput"
													type="file"
													name="files"
													onChange={handleFileChange}
													accept=".pdf,.docx,.doc"
													style={{ display: "none" }}
													ref={(input) => (fileInputRef = input)}
												/>
												<ul>
													{selectedFiles.map((file, i) => (
														<li key={i}>{file.name}</li>
													))}
												</ul>
											</div>
											<Button appearance="primary" onClick={handleUploadClick}>
												Submit
											</Button>
											<br />
										</Field>
										<br />
										{!isUploadInProgress ? null : (
											<Spinner
												appearance="primary"
												labelPosition="after"
												label="Uploading new document..."
											/>
										)}
										{uploadSuccess && (
											<Dialog open={uploadSuccess}>
												<DialogSurface>
													<DialogBody>
														<DialogContent>
															Confirmation of successful CV upload will be sent
															via Email shortly. Please wait for the analysis
															result.
														</DialogContent>
														<DialogActions>
															<Button
																appearance="primary"
																onClick={() => {
																	setUploadSuccess(false);
																	setIsOpen(!isOpen);
																}}
															>
																Got it
															</Button>
														</DialogActions>
													</DialogBody>
												</DialogSurface>
											</Dialog>
										)}
									</div>
								</div>
							</DrawerBody>
						</Drawer>
					</div>
				</Stack>
				<Stack
					className="persona"
					horizontal
					tokens={{ childrenGap: 20 }}
					id={buttonId}
					onClick={toggleIsCalloutVisible}
				>
					{activeAccount?.name ? (
						<Menu>
							<MenuTrigger disableButtonEnhancement>
								{/* <Persona
									textPosition="before"
									name="Kevin Sturgis"
									// presence={{ status: "available" }}
									secondaryText="Available"
								/> */}
								<Tooltip
									content={activeAccount?.idTokenClaims?.roles || "Guest"}
									relationship="label"
								>
									<Persona
										text={activeAccount.name}
										size={PersonaSize.size40}
									/>
								</Tooltip>
							</MenuTrigger>
							<MenuPopover>
								<MenuList>
									<MenuItem
										onClick={() => signOutClickHandler(instance)}
										icon={<SignOut />}
									>
										Sign Out
									</MenuItem>
								</MenuList>
							</MenuPopover>
						</Menu>
					) : (
						<SignInButton />
					)}
				</Stack>
			</Stack>
		</div>
	);
};

export default Topbar;
