import React from "react";
import {
	Radar,
	RadarChart,
	PolarGrid,
	Legend,
	PolarAngleAxis,
	PolarRadiusAxis,
	ResponsiveContainer,
} from "recharts";
import "../../styles/AnalysisDashboard.css";
import { toTitleCase } from "../../utils/titleCase";

function CVRadarChart(props) {
	const renderColorfulLegendText = (value, entry) => {
		return <span className="RadarChart-legend-text">{value}</span>;
	};

	const CustomizedAxisTick = ({ x, y, textAnchor, payload }) => {
		const words = payload.value.split(" ");
		const lines = [];
		let currentLine = [];

		words.forEach((word) => {
			// Check if adding the current word would exceed the length of 10
			if (
				currentLine.length === 3 ||
				(currentLine.length === 2 && currentLine.some((w) => w.length > 10)) ||
				(currentLine.length === 1 && word.length > 10)
			) {
				lines.push(currentLine.join(" "));
				currentLine = [];
			}
			currentLine.push(word);
		});

		// Add the last line if there are remaining words
		if (currentLine.length > 0) {
			lines.push(currentLine.join(" "));
		}

		return (
			<g transform={`translate(${x},${y})`}>
				{lines.map((line, index) => (
					<text
						className="recharts-text recharts-polar-angle-axis-tick-value"
						key={index}
						x={0}
						y={0}
						dy={`${index * 16}px`}
						textAnchor={textAnchor}
					>
						{toTitleCase(line)}
					</text>
				))}
			</g>
		);
	};

	return (
		<ResponsiveContainer width="100%" height="90%">
			<RadarChart cx="50%" cy="50%" outerRadius="55%" data={props.data}>
				<PolarGrid />
				<PolarAngleAxis
					dataKey="skillName"
					tickSize={18}
					tick={CustomizedAxisTick}
				/>
				<PolarRadiusAxis
					angle={0}
					style={{ display: "none" }}
					domain={[0, 10]}
				/>
				<Radar
					name="Requirement Score"
					dataKey="requirementScore"
					stroke="purple"
					fill="transparent"
					fillOpacity={1}
					strokeWidth={3}
				/>
				<Radar
					name="Candidate Score"
					dataKey="candidateScore"
					stroke="darkorange"
					fill="transparent"
					fillOpacity={1}
					strokeWidth={3}
				/>
				<Legend
					className="radar-chart-legend"
					formatter={renderColorfulLegendText}
					wrapperStyle={{ paddingTop: 20, width: "100%" }}
				/>
			</RadarChart>
		</ResponsiveContainer>
	);
}

export default CVRadarChart;
