import React, { useEffect } from "react";
import { makeStyles, Button } from "@fluentui/react-components";
import {
	ChevronRightRegular,
	ChevronLeftRegular,
	ArrowPreviousRegular,
	ArrowNextRegular,
} from "@fluentui/react-icons";
import { PageComponentsProps } from "../models/Candidate";

const useStyles = makeStyles({
	currentPage: {
		backgroundColor: "var(--tag-bg-color)",
		color: "var(--tag-color)",
		fontWeight: "bold",
		border: "1px solid var(--tag-color)",
	},
	pageNumber: {
		fontSize: "12px",
	},
	paginationComponent: {
		display: "flex",
		alignItems: "center",
		gap: "10px",
	},
});

const Pagination = ({
	searchStatus,
	currentPage,
	setCurrentPage,
	totalItems,
	itemsPerPage,
	onPageChange = () => {},
}: PageComponentsProps) => {
	const styles = useStyles();
	const totalPages = totalItems ? Math.ceil(totalItems / itemsPerPage) : 1;

	useEffect(() => {
		if (setCurrentPage) {
			console.log(
				"totalItems, searchStatus, currentPage, totalPages",
				totalItems,
				searchStatus,
				currentPage,
				totalPages
			);

			setCurrentPage(1);
		}
	}, [searchStatus, itemsPerPage, setCurrentPage]);

	const goToPage = (page: number) => {
		if (setCurrentPage) {
			setCurrentPage(page);
		}
		onPageChange(page);
	};

	const renderPages = () => {
		const pages = [];
		if (totalPages <= 5) {
			for (let i = 1; i <= totalPages; i++) {
				pages.push(
					<Button
						icon={<p className={styles.pageNumber}>{i}</p>}
						onClick={() => goToPage(i)}
						key={`page-${i}`}
						className={currentPage === i ? styles.currentPage : ""}
					/>
				);
			}
		} else {
			if (currentPage! <= 3) {
				for (let i = 1; i <= 4; i++) {
					pages.push(
						<Button
							icon={<p className={styles.pageNumber}>{i}</p>}
							onClick={() => goToPage(i)}
							key={`page-${i}`}
							className={currentPage === i ? styles.currentPage : ""}
						/>
					);
				}
				pages.push(<span key="ellipsis1">...</span>);
				pages.push(
					<Button
						icon={<p className={styles.pageNumber}>{totalPages}</p>}
						key={totalPages}
						onClick={() => goToPage(totalPages)}
						className={currentPage === totalPages ? styles.currentPage : ""}
					/>
				);
			} else if (currentPage! > totalPages - 3) {
				pages.push(
					<Button
						icon={<p className={styles.pageNumber}>{1}</p>}
						key={1}
						onClick={() => goToPage(1)}
						className={currentPage === 1 ? styles.currentPage : ""}
					/>
				);
				pages.push(<span key="ellipsis2">...</span>);
				for (let i = totalPages - 3; i <= totalPages; i++) {
					pages.push(
						<Button
							icon={<p className={styles.pageNumber}>{i}</p>}
							onClick={() => goToPage(i)}
							key={`page-${i}`}
							className={currentPage === i ? styles.currentPage : ""}
						/>
					);
				}
			} else {
				pages.push(
					<Button
						icon={<p className={styles.pageNumber}>1</p>}
						key={1}
						onClick={() => goToPage(1)}
						className={currentPage === 1 ? styles.currentPage : ""}
					/>
				);
				pages.push(<span key="ellipsis3">...</span>);
				for (let i = currentPage! - 1; i <= currentPage! + 1; i++) {
					pages.push(
						<Button
							icon={<p className={styles.pageNumber}>{i}</p>}
							onClick={() => goToPage(i)}
							key={`page-${i}`}
							className={currentPage === i ? styles.currentPage : ""}
						/>
					);
				}
				pages.push(<span key="ellipsis4">...</span>);
				pages.push(
					<Button
						icon={<p className={styles.pageNumber}>{totalPages}</p>}
						key={totalPages}
						onClick={() => goToPage(totalPages)}
						className={currentPage === totalPages ? styles.currentPage : ""}
					/>
				);
			}
		}
		return pages;
	};

	return (
		<div className={styles.paginationComponent}>
			<Button
				icon={<ArrowPreviousRegular />}
				onClick={() => goToPage(1)}
				disabled={!totalItems || !searchStatus || currentPage === 1}
			/>
			<Button
				icon={<ChevronLeftRegular />}
				onClick={() => goToPage(currentPage! - 1)}
				disabled={!totalItems || !searchStatus || currentPage === 1}
			/>
			{renderPages()}
			<Button
				icon={<ChevronRightRegular />}
				onClick={() => goToPage(currentPage! + 1)}
				disabled={!totalItems || !searchStatus || currentPage === totalPages}
			/>
			<Button
				icon={<ArrowNextRegular />}
				onClick={() => goToPage(totalPages)}
				disabled={!totalItems || !searchStatus || currentPage === totalPages}
			/>
		</div>
	);
};

export default Pagination;
