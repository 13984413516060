import React, { useState, useEffect } from "react";

import {
	Dialog,
	DialogTrigger,
	DialogSurface,
	DialogTitle,
	DialogBody,
	DialogContent,
	Button,
} from "@fluentui/react-components";
import {
	bundleIcon,
	DocumentOnePageSparkleRegular,
	DocumentOnePageSparkleFilled,
	DismissRegular,
} from "@fluentui/react-icons";

function ViewCVModal(props: any) {
	const { selectedIntID, candidateDetails } = props;
	const [src, setSrc] = useState("");
	const [preview, setPreview] = useState("");
	const DocumentOnePageSparkle = bundleIcon(
		DocumentOnePageSparkleFilled,
		DocumentOnePageSparkleRegular
	);

	useEffect(() => {
		if (candidateDetails) {
			setSrc(candidateDetails.cvImgSrc);
			setPreview(candidateDetails.pdfUrl);
		}
	}, [candidateDetails]);

	return (
		<div>
			<Dialog modalType="non-modal">
				<DialogTrigger disableButtonEnhancement>
					<Button
						icon={<DocumentOnePageSparkle />}
						disabled={!candidateDetails || candidateDetails.pdfUrl === ""}
						onClick={(e) => e.stopPropagation()}
					>
						View CV
					</Button>
				</DialogTrigger>
				<DialogSurface
					className="cv-dialog-surface"
					// style={{ maxWidth: "80vh" }}
				>
					<DialogBody>
						<DialogTitle
							action={
								<DialogTrigger action="close">
									<Button
										appearance="subtle"
										aria-label="close"
										icon={<DismissRegular />}
										onClick={(e) => e.stopPropagation()}
									/>
								</DialogTrigger>
							}
						></DialogTitle>
						{candidateDetails ? (
							<DialogContent>
								<iframe
									title="cv-preview"
									className="preview-pdf"
									src={preview}
								/>
							</DialogContent>
						) : (
							<div>Unable to show preview of candidate's CV</div>
						)}
					</DialogBody>
				</DialogSurface>
			</Dialog>
		</div>
	);
}
export default ViewCVModal;
