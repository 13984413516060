import { makeStyles, Spinner } from "@fluentui/react-components";

const useStyles = makeStyles({
	loadingOverlay: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(255, 255, 255, 0.5)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 1000,
	},
});

const LoadingSpinner = () => {
	const styles = useStyles();

	return (
		<div className={styles.loadingOverlay}>
			<Spinner label="Loading..." />
		</div>
	);
};

export default LoadingSpinner;
