import React, { useState, useEffect } from "react";

import {
	Button,
} from "@fluentui/react-components";
import {
	bundleIcon,
	DocumentOnePageSparkleRegular,
	DocumentOnePageSparkleFilled,
	DocumentDataRegular,
} from "@fluentui/react-icons";

export function ViewReportModal(props: any) {
	const { selectedIntID, candidateDetails } = props;
	const [src, setSrc] = useState("");
	const [preview, setPreview] = useState("");
	const DocumentOnePageSparkle = bundleIcon(
		DocumentOnePageSparkleFilled,
		DocumentOnePageSparkleRegular
	);

	useEffect(() => {
		if (candidateDetails) {
			setSrc(candidateDetails.cvImgSrc);
			setPreview(candidateDetails.pdfUrl);
		}
	}, [candidateDetails]);

	return (
		<Button
			className="candidates-detail-page-top-bar tool-bar-button view-report-button"
			icon={<DocumentDataRegular />}
			onClick={(e) => {
				e.stopPropagation();
				window.open(`candidates/dashboard/${selectedIntID}`, "_blank");
			}}
			disabled={
				candidateDetails && !candidateDetails.hasAnalysis ? true : false
			}
		>
			View Report
		</Button>
	);
}
