export function toTitleCase(str: string | undefined | null): string {
    if (typeof str !== 'string' || str.trim() === '') {
        return '';
    }
    
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}