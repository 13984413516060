import React from "react";

import { makeStyles } from "@fluentui/react-components";

import { PageComponentsProps } from "../models/Candidate"

import Pagination from "./Pagination";
import ViewItemsPerPage from "./ViewItemsPerPage";

const useStyles = makeStyles({
	paginationContainer: {
		display: "flex",
		flexDirection: "column",
		padding: "12px",
		rowGap: "6px",
		position: "relative",
	},
	pageRangeContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
	},
});

const PaginationContainer = ({
	candidatesData,
	searchStatus,
	currentPage,
	setCurrentPage,
	totalItems,
	itemsPerPage,
	onItemsPerPageChange,
	onPageChange,
}: PageComponentsProps) => {
	const styles = useStyles();
	return (
		<div className={styles.paginationContainer}>
			<div className={styles.pageRangeContainer}>
				<ViewItemsPerPage
					searchStatus={searchStatus}
					totalItems={candidatesData ? candidatesData.meta.total : 0}
					itemsPerPage={itemsPerPage}
					onItemsPerPageChange={onItemsPerPageChange}
				/>
				<Pagination
					searchStatus={searchStatus}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					totalItems={candidatesData ? candidatesData.meta.total : 0}
					itemsPerPage={itemsPerPage}
					onPageChange={onPageChange}
				/>
			</div>
		</div>
	);
};

export default PaginationContainer;
