import React, {
	ChangeEvent,
	MouseEvent,
	useState,
	useEffect,
	useCallback,
} from "react";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import axios from "axios";
import {
	makeStyles,
	Badge,
	Body1,
	Body2,
	Button,
	Caption1,
	Card,
	CardFooter,
	CardHeader,
	CardPreview,
	Checkbox,
	Divider,
	Input,
	Label,
	Title3,
	Dropdown,
	Option,
	ToggleButton,
	OverlayDrawer,
	DrawerBody,
	DrawerHeader,
	DrawerHeaderNavigation,
	DrawerHeaderTitle,
	DrawerFooter,
} from "@fluentui/react-components";
import {
	ITheme,
	mergeStyleSets,
	getTheme,
	getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { DefaultButton, PrimaryButton, Text, TextField } from "@fluentui/react";
import { Stack, IStackTokens } from "@fluentui/react/lib/Stack";

import {
	Info16Regular,
	AddRegular,
	ChevronRightRegular,
	ChevronLeftRegular,
	DismissRegular,
	ArrowUploadRegular,
	Search20Regular,
	CalendarRegular,
	Filter20Regular,
	KeyboardRegular,
	FilterDismissRegular,
	DeleteRegular,
	ArrowPreviousRegular,
	ArrowNextRegular,
} from "@fluentui/react-icons";

import { formattedDate } from "../../utils/dateUtils";
import ViewCVModal from "../../components/ViewCVModal";
import { ViewReportModal } from "../../components/ViewReportModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import CandidatesDetailDrawer from "./CandidatesDetailDrawer";
import DeleteCandidateDialog from "../../components/DeleteCandidateDialog";

import {
	Candidate,
	CandidateSearch,
	searchFields,
} from "../../models/Candidate";

import {
	yearOfExperienceOptions,
	sourceOptions,
	statusOptions,
} from "../../constants/dropdownOptions";

const theme: ITheme = getTheme();

const { palette, semanticColors, fonts } = theme;
const classNames = mergeStyleSets({
	itemName: [
		fonts.xLarge,
		{
			whiteSpace: "nowrap",
			color: "var(--jardines-80)",
			textOverflow: "ellipsis",
		},
	],
	itemIndex: {
		fontSize: fonts.small.fontSize,
		color: palette.neutralTertiary,
		marginBottom: 10,
	},
	itemCategory: {
		fontWeight: "bold",
		color: "#858585",
	},
	pageDropdown: {
		// ">.fui-Dropdown": {
		// minWidth: "50px",
		// },
		">.fui-Dropdown__button": {
			// width: "50px",
		},
	},
});

const stackStyles = {
	root: {
		display: "flex",
		flexDirection: "column",
	},
};

const rowStyles = {
	root: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
};

const cellStyles = {
	root: {
		width: "25%",
		padding: "8px",
	},
};

const descriptionStyles = {
	root: {
		flex: 1,
		padding: "8px",
	},
};

export default function Candidates() {
	const [fields, setFields] = useState<searchFields>({
		name: undefined,
		title: undefined,
		skill: undefined,
		company: undefined,
		yearOfExperience: undefined,
		school: undefined,
		source: undefined,
		status: undefined,
	});

	const [searchCandidates, setSearchCandidates] = useState(false);

	const [display, setDisplay] = useState({
		searchIcon: true,
		loadingSpinner: false,
		detailBox: false,
	});

	const [selectedSkillIndex, setSelectedSkillIndex] = useState<
		number | undefined
	>(undefined);

	const [candidatesData, setCandidatesData] = useState<
		CandidateSearch | undefined
	>(undefined);
	const [selectedCandidateID, setSelectedCandidateID] = useState<
		number | undefined
	>(undefined);

	const [isDetailDrawerOpen, setIsDetailDrawerOpen] = useState(false);
	const [isSearchPanelVisible, setIsSearchPanelVisible] = useState(true);

	const [page, setPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(25);

	const [selectAllMsg, setSelectAllMsg] = useState("Select All in the page");
	const [selectAllBox, setSelectAllBox] = useState(false);
	const [selectionArr, setSelectionArr] = useState<number[]>([]);

	const newSelectAllState = !selectAllBox;

	const {
		isLoading,
		error,
		data: retrieveData,
		refetch,
	} = useQuery(
		["all-candidates"],
		() =>
			axios
				.post(`/candidates/search`, fields)
				.then((response) => response.data),
		{
			refetchOnWindowFocus: false,
			enabled: searchCandidates, // Ensure this is `true` when you want to fetch data
			onSuccess: (data) => {
				setDisplay((prev) => ({
					...prev,
					searchIcon: false,
					loadingSpinner: false,
					detailBox: true,
				}));
				setCandidatesData(data);
			},
		}
	);
	console.log("searchCandidates", searchCandidates);
	// console.log("global isLoading:", isLoading);
	console.log("display:", display);

	function triggerSearch() {
		setDisplay((prev) => ({
			...prev,
			searchIcon: false,
			loadingSpinner: true,
			detailBox: false,
		}));
		setSearchCandidates(false);
		setTimeout(() => {
			setSearchCandidates(true);
		}, 1000);
		// console.log("search candidates", fields);
	}

	const handleReadMoreClick = (
		e: MouseEvent<HTMLDivElement>,
		itemIndex: number | undefined
	) => {
		e.preventDefault();
		if (itemIndex === undefined) return;

		// Use functional state update to ensure the latest state is used
		setCandidatesData((prevData) => {
			if (!prevData) return prevData; // If prevData is undefined, return it as is

			const updatedResult = prevData.result.map((candidate, index) => {
				if (index === itemIndex) {
					return {
						...candidate,
						isSkillExpanded: !candidate.isSkillExpanded,
					};
				}
				return candidate;
			});

			return {
				...prevData,
				result: updatedResult,
			};
		});

		// Optional: Update selectedSkillIndex if needed
		setSelectedSkillIndex(itemIndex);
		console.log("expanded?");
		console.log("itemIndex", itemIndex);
	};

	const [selectedState, setSelectedState] = useState<Record<number, boolean>>(
		{}
	);
	// Abstraction for setting state
	const setCheckboxState = useCallback(
		({ selected, checked }, setFn) => setFn(!!(selected || checked)),
		[]
	);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFields((prev) => ({
			...prev,
			[name]: value === "" ? undefined : value,
		}));
	};

	const handleSelectionChange = useCallback((rowId: number, state) => {
		const isChecked = state.selected || state.checked;
		setSelectedState((prevState) => ({
			...prevState,
			[rowId]: isChecked,
		}));
		setSelectionArr((prevArr) => {
			if (isChecked) {
				if (!prevArr.includes(rowId)) {
					return [...prevArr, rowId];
				}
				return prevArr;
			} else {
				return prevArr.filter((id) => id !== rowId);
			}
		});
	}, []);

	const handleSelectAll = (e: any, data: any) => {
		if (candidatesData) {
			const allCandidatesIDArr = candidatesData.result.map(
				(candidate) => candidate.id
			);
			// console.log("allCandidatesIDArr", allCandidatesIDArr);

			if (data.checked) {
				setSelectAllMsg("Unselect All in the page");
				setSelectAllBox(true);
				allCandidatesIDArr.forEach((id) => {
					setSelectedState((prevState) => ({
						...prevState,
						[id]: true,
					}));
					setSelectionArr(allCandidatesIDArr);
				});
			} else {
				setSelectAllMsg("Select All in the page");
				setSelectAllBox(false);
				allCandidatesIDArr.forEach((id) => {
					setSelectedState((prevState) => ({
						...prevState,
						[id]: false,
					}));
					setSelectionArr([]);
				});
			}
		}
	};

	const onKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault();
			triggerSearch();
			// setDisplay((prev) => ({ ...prev, searchIcon: false }));
			console.log("clicked enter");
		}
	};

	const [selectedCandidateDetails, setSelectedCandidateDetails] = useState<
		Candidate[]
	>([]);

	const onActionCardClick = (
		e: MouseEvent<HTMLDivElement>,
		id: number | undefined,
		index: number | undefined
	) => {
		e.preventDefault();
		if (candidatesData && index !== undefined) {
			setIsDetailDrawerOpen(true);
			setSelectedCandidateID(id);
			setSelectedCandidateDetails([candidatesData.result[index]]);
			setIsSearchPanelVisible(false);
		}
	};

	const [isDeletingCandidate, setIsDeletingCandidate] = useState(false);
	const [deleteStatus, setDeleteStatus] = useState<string | undefined>(
		undefined
	);

	const queryClient = useQueryClient();

	// TO DO: error display

	const deleteCandidate = useMutation({
		mutationFn: (ids: number | number[]) => {
			setIsDeletingCandidate(true);
			const idArray = Array.isArray(ids) ? ids : [ids];
			return Promise.all(
				idArray.map((id) =>
					axios.delete(`/candidates/${id}`, {
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
					})
				)
			).then((responses) => responses.map((response) => response.data));
		},
		onSuccess: (data) => {
			setIsDeletingCandidate(false);
			console.log("Deleted candidates", data);
			setDeleteStatus("success");
		},
		onError: (error) => {
			setIsDeletingCandidate(false);
			console.error(error);
			setDeleteStatus("error");
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["all-candidates"] });
		},
	});

	const handleDeleteConfirm = () => {
		setDeleteStatus(undefined);
		setIsDetailDrawerOpen(false);
		setIsSearchPanelVisible(true);
		setSelectionArr([]);
		refetch();
	};

	// console.log("new candidatesData", candidatesData);
	// console.log("selectedState", selectedState);
	// console.log("selectionArr", selectionArr);
	// console.log("selectAllBox", selectAllBox);
	// console.log("fields", fields);
	// console.log("SearchCandidates", searchCandidates);
	// console.log("isLoading", isLoading);
	// console.log("selectedCandidateDetails", selectedCandidateDetails);

	const [isMobileFilterOpen, setIsMobileFilterOpen] = React.useState(false);

	return (
		<>
			<div className="search-candidate-content">
				<OverlayDrawer
					size="medium"
					position="start"
					open={isMobileFilterOpen}
					onOpenChange={(_, { open }) => setIsMobileFilterOpen(open)}
				>
					<DrawerHeader>
						<>
							<div
								className="filter-drawer-header"
								style={{
									display: "flex",
									flexDirection: "column",
									padding: "12px",
									rowGap: "6px",
								}}
							>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									<div
										style={{
											display: "flex",
											padding: "0 10px ",
										}}
									>
										<div
											style={{
												display: "flex",
												// alignItems: "end",
												alignItems: "center",
												flexShrink: 0,
												columnGap: "8px",
												height: "32px",
												color: "var(--jardines-80)",
												fontWeight: 600,
											}}
										>
											<Filter20Regular />
											<span
												style={{
													fontWeight: 600,
												}}
											>
												Filter
											</span>
										</div>
									</div>

									<Button
										aria-label="Close panel"
										appearance="subtle"
										icon={<DismissRegular />}
										onClick={() => setIsMobileFilterOpen(false)}
									/>
								</div>
								<Divider />
							</div>
						</>
					</DrawerHeader>

					<DrawerBody>
						<div
							style={{
								position: "relative",
								padding: "0 24px",
								display: "flex",
								rowGap: "20px",
								flexDirection: "column",
								overflowY: "auto",
								flexGrow: 1,
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									rowGap: "3px",
								}}
							>
								<Label style={{ fontSize: "14px", fontWeight: "600" }}>
									Name
								</Label>
								<Input
									name="name"
									appearance="filled-darker"
									placeholder="Enter Candidate Name"
									style={{ border: "1px solid #999999" }}
									value={fields.name || ""}
									onChange={handleChange}
									onKeyDown={onKeyDown}
								/>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									rowGap: "3px",
								}}
							>
								<Label style={{ fontSize: "14px", fontWeight: "600" }}>
									Job Title
								</Label>
								<Input
									name="title"
									appearance="filled-darker"
									placeholder="Enter Job Title"
									style={{ border: "1px solid #999999" }}
									value={fields.title || ""}
									onChange={handleChange}
									onKeyDown={onKeyDown}
								/>
							</div>

							<div
								style={{
									display: "flex",
									flexDirection: "column",
									rowGap: "3px",
								}}
							>
								<Label style={{ fontSize: "14px", fontWeight: "600" }}>
									Skills
								</Label>
								<Input
									name="skill"
									appearance="filled-darker"
									placeholder="Enter Skills"
									style={{ border: "1px solid #999999" }}
									value={fields.skill || ""}
									onChange={handleChange}
									onKeyDown={onKeyDown}
								/>
							</div>

							<div
								style={{
									display: "flex",
									flexDirection: "column",
									rowGap: "3px",
								}}
							>
								<Label style={{ fontSize: "14px", fontWeight: "600" }}>
									Current/Previous Company
								</Label>
								<Input
									name="company"
									appearance="filled-darker"
									placeholder="Enter Company Name"
									style={{ border: "1px solid #999999" }}
									value={fields.company || ""}
									onChange={handleChange}
									onKeyDown={onKeyDown}
								/>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									rowGap: "3px",
								}}
							>
								<Label style={{ fontSize: "14px", fontWeight: "600" }}>
									Years of Work Experience
								</Label>
								<Dropdown
									name="yearOfExperience"
									appearance="filled-darker"
									placeholder="Select Range"
									style={{ border: "1px solid #999999" }}
									onOptionSelect={(ev, data) => {
										setFields((prev) => ({
											...prev,
											yearOfExperience: data.optionValue,
										}));
									}}
									clearable
									selectedOptions={
										fields.yearOfExperience
											? [String(fields.yearOfExperience)]
											: []
									}
									value={
										fields.yearOfExperience ? `${fields.yearOfExperience}` : ""
									}
								>
									{yearOfExperienceOptions.map((option) => (
										<Option key={option.key}>{option.text}</Option>
									))}
								</Dropdown>
							</div>

							<div
								style={{
									display: "flex",
									flexDirection: "column",
									rowGap: "3px",
								}}
							>
								<Label style={{ fontSize: "14px", fontWeight: "600" }}>
									School
								</Label>
								<Input
									name="school"
									appearance="filled-darker"
									placeholder="Enter School Name"
									style={{ border: "1px solid #999999" }}
									value={fields.school || ""}
									onChange={handleChange}
									onKeyDown={onKeyDown}
								/>
							</div>

							<div
								style={{
									display: "flex",
									flexDirection: "column",
									rowGap: "3px",
								}}
							>
								<Label style={{ fontSize: "14px", fontWeight: "600" }}>
									Source
								</Label>
								<Dropdown
									name="source"
									appearance="filled-darker"
									placeholder="Select Source"
									style={{ border: "1px solid #999999" }}
									onOptionSelect={(ev, data) => {
										setFields((prev) => ({
											...prev,
											source: data.optionValue,
										}));
									}}
									clearable
									selectedOptions={fields.source ? [String(fields.source)] : []}
									value={fields.source ? `${fields.source}` : ""}
								>
									{sourceOptions.map((option) => (
										<Option key={option.key}>{option.text}</Option>
									))}
								</Dropdown>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									rowGap: "3px",
								}}
							>
								<Label style={{ fontSize: "14px", fontWeight: "600" }}>
									Status
								</Label>
								<Dropdown
									name="status"
									appearance="filled-darker"
									placeholder="Select Status"
									style={{ border: "1px solid #999999" }}
									onOptionSelect={(ev, data) => {
										setFields((prev) => ({
											...prev,
											status: data.optionValue,
										}));
									}}
									clearable
									selectedOptions={fields.status ? [String(fields.status)] : []}
									value={fields.status ? `${fields.status}` : ""}
								>
									{statusOptions.map((option) => (
										<Option key={option.key}>{option.text}</Option>
									))}
								</Dropdown>
							</div>
						</div>
					</DrawerBody>

					<DrawerFooter>
						<span
							style={{
								paddingTop: "18px",
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
							}}
						>
							<Button
								appearance="primary"
								onClick={() => {
									triggerSearch();
									setIsMobileFilterOpen(false);
								}}
							>
								Search
							</Button>
							<Button
								style={{ border: "1px solid var(--jardines-80)" }}
								onClick={(e) =>
									setFields({
										name: undefined,
										title: undefined,
										skill: undefined,
										company: undefined,
										yearOfExperience: undefined,
										school: undefined,
										source: undefined,
										status: undefined,
									})
								}
							>
								Clear All
							</Button>
						</span>
					</DrawerFooter>
				</OverlayDrawer>

				{isSearchPanelVisible && (
					<>
						<div
							className="search-panel-filter-mobile"
							style={{
								backgroundColor: "#FFFFFF",
								boxShadow:
									"0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14)",
								borderRadius: "4px",
							}}
							onClick={() => setIsMobileFilterOpen(true)}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									// justifyContent: "end",
									// flexShrink: 0,
									padding: "12px",
									rowGap: "6px",
									// backgroundColor: "#ECE5FD",
								}}
							>
								<div
									style={{
										display: "flex",
										// flexShrink: 0,
										padding: "0 10px ",
									}}
								>
									<div
										style={{
											display: "flex",
											// alignItems: "end",
											alignItems: "center",
											flexShrink: 0,
											columnGap: "8px",
											height: "32px",
											color: "#6666CC",
											// color: "#858585",
											fontWeight: "600",
										}}
									>
										<Filter20Regular />
										<span
											style={
												{
													// color: "#242424",
													// fontSize: "12px",
													// fontWeight: "bold",
												}
											}
										>
											Filter
										</span>
									</div>
								</div>
							</div>
						</div>

						<div className="search-panel-filter">
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									position: "relative",
									// padding: "0 24px",
									backgroundColor: "#FFFFFF",
									boxShadow:
										"0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14)",
									borderRadius: "4px",
									height: "100%",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										// justifyContent: "end",
										// flexShrink: 0,
										padding: "12px",
										rowGap: "6px",
										// backgroundColor: "#ECE5FD",
									}}
								>
									<div
										style={{
											display: "flex",
											// flexShrink: 0,
											padding: "0 10px ",
										}}
									>
										<div
											style={{
												display: "flex",
												// alignItems: "end",
												alignItems: "center",
												flexShrink: 0,
												columnGap: "8px",
												height: "32px",
												color: "var(--jardines-80)",
												fontWeight: 600,
											}}
										>
											<Filter20Regular />
											<span
												style={{
													fontWeight: 600,
												}}
											>
												Filter
											</span>
										</div>
									</div>
								</div>
								<Divider
									style={{ display: "flex", flexShrink: 0, flexGrow: 0 }}
								/>
								<br />
								<div
									style={{
										position: "relative",
										padding: "0 24px",
										display: "flex",
										rowGap: "20px",
										flexDirection: "column",
										overflowY: "auto",
										flexGrow: 1,
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: "3px",
										}}
									>
										<Label style={{ fontSize: "14px", fontWeight: "600" }}>
											Name
										</Label>
										<Input
											name="name"
											appearance="filled-darker"
											placeholder="Enter Candidate Name"
											style={{ border: "1px solid #999999" }}
											value={fields.name || ""}
											onChange={handleChange}
											onKeyDown={onKeyDown}
										/>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: "3px",
										}}
									>
										<Label style={{ fontSize: "14px", fontWeight: "600" }}>
											Job Title
										</Label>
										<Input
											name="title"
											appearance="filled-darker"
											placeholder="Enter Job Title"
											style={{ border: "1px solid #999999" }}
											value={fields.title || ""}
											onChange={handleChange}
											onKeyDown={onKeyDown}
										/>
									</div>

									<div
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: "3px",
										}}
									>
										<Label style={{ fontSize: "14px", fontWeight: "600" }}>
											Skills
										</Label>
										<Input
											name="skill"
											appearance="filled-darker"
											placeholder="Enter Skills"
											style={{ border: "1px solid #999999" }}
											value={fields.skill || ""}
											onChange={handleChange}
											onKeyDown={onKeyDown}
										/>
									</div>

									<div
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: "3px",
										}}
									>
										<Label style={{ fontSize: "14px", fontWeight: "600" }}>
											Current/Previous Company
										</Label>
										<Input
											name="company"
											appearance="filled-darker"
											placeholder="Enter Company Name"
											style={{ border: "1px solid #999999" }}
											value={fields.company || ""}
											onChange={handleChange}
											onKeyDown={onKeyDown}
										/>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: "3px",
										}}
									>
										<Label style={{ fontSize: "14px", fontWeight: "600" }}>
											Years of Work Experience
										</Label>
										<Dropdown
											name="yearOfExperience"
											appearance="filled-darker"
											placeholder="Select Range"
											style={{ border: "1px solid #999999" }}
											onOptionSelect={(ev, data) => {
												setFields((prev) => ({
													...prev,
													yearOfExperience: data.optionValue,
												}));
											}}
											clearable
											selectedOptions={
												fields.yearOfExperience
													? [String(fields.yearOfExperience)]
													: []
											}
											value={
												fields.yearOfExperience
													? `${fields.yearOfExperience}`
													: ""
											}
										>
											{yearOfExperienceOptions.map((option) => (
												<Option key={option.key}>{option.text}</Option>
											))}
										</Dropdown>
									</div>

									<div
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: "3px",
										}}
									>
										<Label style={{ fontSize: "14px", fontWeight: "600" }}>
											School
										</Label>
										<Input
											name="school"
											appearance="filled-darker"
											placeholder="Enter School Name"
											style={{ border: "1px solid #999999" }}
											value={fields.school || ""}
											onChange={handleChange}
											onKeyDown={onKeyDown}
										/>
									</div>

									<div
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: "3px",
										}}
									>
										<Label style={{ fontSize: "14px", fontWeight: "600" }}>
											Source
										</Label>
										<Dropdown
											name="source"
											appearance="filled-darker"
											placeholder="Select Source"
											style={{ border: "1px solid #999999" }}
											onOptionSelect={(ev, data) => {
												setFields((prev) => ({
													...prev,
													source: data.optionValue,
												}));
											}}
											clearable
											selectedOptions={
												fields.source ? [String(fields.source)] : []
											}
											value={fields.source ? `${fields.source}` : ""}
										>
											{sourceOptions.map((option) => (
												<Option key={option.key}>{option.text}</Option>
											))}
										</Dropdown>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: "3px",
										}}
									>
										<Label style={{ fontSize: "14px", fontWeight: "600" }}>
											Status
										</Label>
										<Dropdown
											name="status"
											appearance="filled-darker"
											placeholder="Select Status"
											style={{ border: "1px solid #999999" }}
											onOptionSelect={(ev, data) => {
												setFields((prev) => ({
													...prev,
													status: data.optionValue,
												}));
											}}
											clearable
											selectedOptions={
												fields.status ? [String(fields.status)] : []
											}
											value={fields.status ? `${fields.status}` : ""}
										>
											{statusOptions.map((option) => (
												<Option key={option.key}>{option.text}</Option>
											))}
										</Dropdown>
									</div>
									<span
										style={{
											paddingTop: "18px",
											display: "flex",
											justifyContent: "space-between",
										}}
									>
										<Button
											appearance="primary"
											onClick={() => {
												triggerSearch();
											}}
										>
											Search
										</Button>
										<Button
											style={{ border: "1px solid var(--jardines-80)" }}
											onClick={(e) =>
												setFields({
													name: undefined,
													title: undefined,
													skill: undefined,
													company: undefined,
													yearOfExperience: undefined,
													school: undefined,
													source: undefined,
													status: undefined,
												})
											}
										>
											Clear All
										</Button>
									</span>
								</div>
							</div>
						</div>
					</>
				)}

				<div className="search-candidate-result">
					<div
						style={{
							position: "relative",
							// padding: "0 24px",
							height: "100%",
							// minWidth: "300px",

							display: "flex",
							// rowGap: "10px",
							flexDirection: "column",
							flexGrow: 1,
							// overflowY: "auto",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								// justifyContent: "end",
								// flexShrink: 0,
								padding: "12px",
								rowGap: "6px",
							}}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									// flexShrink: 0,
									padding: "0 10px ",
								}}
							>
								<div
									style={{
										display: "flex",
										// alignItems: "end",
										alignItems: "center",
										// flexShrink: 0,
										columnGap: "8px",
									}}
								>
									<Search20Regular />
									<span
										style={{
											color: "#242424",
											// fontSize: "12px",
											// fontWeight: "bold",
										}}
									>
										{candidatesData &&
											`Showing ${candidatesData.meta.total} result(s)`}
										{selectionArr.length > 0
											? `, ${selectionArr.length} selected `
											: null}
									</span>
								</div>
								<div style={{ display: "flex", columnGap: "10px" }}>
									<Button
										icon={
											<Checkbox
												onChange={(e, data) => {
													const checked =
														typeof data.checked === "boolean"
															? data.checked
															: false;
													handleSelectAll(e, {
														checked,
													});
												}}
												checked={selectAllBox}
											/>
										}
										onClick={(e) => {
											setSelectAllBox(newSelectAllState);

											handleSelectAll(null, { checked: newSelectAllState });
										}}
										disabled={!candidatesData}
									>
										{selectAllMsg}
									</Button>
									{/* <Button
										icon={<DismissRegular />}
										// appearance="subtle"
										disabled={selectionArr.length === 0}
										style={{
											backgroundColor:
												selectionArr.length === 0 ? "" : "#f5574b",
											color: selectionArr.length === 0 ? "" : "#ffffff",
										}}
									>
										Delete
									</Button> */}
									<DeleteCandidateDialog
										deleteButtonLabel={"Delete"}
										candidatesData={
											candidatesData ? candidatesData.result : undefined
										}
										selectionArr={selectionArr}
										deleteCandidate={deleteCandidate}
										handleDeleteConfirm={handleDeleteConfirm}
										deleteStatus={deleteStatus}
									/>
								</div>
							</div>
						</div>
						<Divider />

						<div
							style={{
								display: "flex",
								flexDirection: "column",
								height: "100%",
								flexGrow: 1,
								backgroundColor: "#F0F0F0#F0F0F0",
								padding: "24px",
								overflowY: "auto",
								position: "relative",
							}}
							className="candaidates-list"
						>
							{display.searchIcon ? (
								<div className="search-icon-container">
									<div>
										<img
											src="SearchIcon.svg"
											alt="searchIcon"
											width={700}
											style={{ opacity: "85%" }}
										/>
										<div className="search-icon-text">
											<Text block variant="xxLarge">
												Start a Search
											</Text>
											<Text block variant="mediumPlus">
												You haven't started a search yet. Use the Search Filter
												to start finding candidates.
											</Text>
										</div>
									</div>
								</div>
							) : display.loadingSpinner ? (
								<LoadingSpinner />
							) : (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										rowGap: "24px",
										flexGrow: 1,
									}}
								>
									{candidatesData && candidatesData?.result?.length > 0 ? (
										<>
											{candidatesData.result.map((item, itemIndex) => (
												<Card
													key={`candidate-${itemIndex}`}
													style={{ height: "400px", padding: "24px", gap: 0 }}
													onClick={(e) =>
														onActionCardClick(e, item.id, itemIndex)
													}
													floatingAction={
														<div
															style={{
																display: "flex",
																columnGap: "10px",
																padding: "24px",
															}}
														>
															<Checkbox
																onClick={(e) => e.stopPropagation()}
																onChange={(e) => {
																	e.stopPropagation();
																	if (item.id !== undefined) {
																		handleSelectionChange(item.id, {
																			selected: e.target.checked,
																			checked: e.target.checked,
																		});
																	}
																}}
																checked={!!selectedState[item.id!]}
															/>
														</div>
													}
												>
													<CardHeader
														header={
															<div className={classNames.itemName}>
																{item.name}
															</div>
														}
														description={
															<div
																className={classNames.itemIndex}
															>{`Last modified: ${formattedDate(
																item.lastModified
															)}`}</div>
														}
													/>
													<Badge
														shape="rounded"
														// appearance="tint"
														style={{
															backgroundColor: "var(--tag-bg-color)",
															color: "var(--tag-color)",
														}}
													>
														{item.currentProfile}
													</Badge>

													<CardPreview style={{ padding: "12px" }}>
														<Stack horizontalAlign="start" styles={stackStyles}>
															{/* Header Row */}
															<Stack horizontal styles={rowStyles}>
																<Stack.Item styles={cellStyles}>
																	<Text
																		className={classNames.itemCategory}
																		variant="medium"
																		nowrap
																		block
																	>
																		Experience
																	</Text>
																</Stack.Item>
																<Stack.Item styles={descriptionStyles}>
																	<Text variant="medium" block>
																		{item.workExperience[0] ? (
																			item.workExperience
																				.slice(0, 3)
																				.map((record, index) => (
																					<div
																						key={`candidate-${item.id}-workExperience-${index}`}
																					>
																						{record.title} at {record.company},{" "}
																						{record.yearRange}
																					</div>
																				))
																		) : (
																			<Text style={{ fontStyle: "italic" }}>
																				No Record
																			</Text>
																		)}
																	</Text>
																</Stack.Item>
															</Stack>

															<Stack horizontal styles={rowStyles}>
																<Stack.Item styles={cellStyles}>
																	<Text
																		className={classNames.itemCategory}
																		variant="medium"
																		nowrap
																		block
																	>
																		Skills
																	</Text>
																</Stack.Item>
																<Stack.Item
																	styles={descriptionStyles}
																	key={itemIndex}
																>
																	{item.isSkillExpanded ||
																	(item.skills && item.skills.length < 300) ? (
																		<Text variant="medium" block>
																			{item.skills}
																		</Text>
																	) : item.skills &&
																	  item.skills.length > 300 ? (
																		<>
																			<Text
																				style={{
																					WebkitLineClamp: 2,
																					WebkitBoxOrient: "vertical",
																					overflow: "hidden",
																					display: "-webkit-box",
																				}}
																				variant="medium"
																				block
																			>
																				{item.skills}
																			</Text>
																			<div
																				style={{
																					color: "#0078D4",
																					cursor: "pointer",
																				}}
																				onClick={(e) => {
																					e.stopPropagation();
																					handleReadMoreClick(e, itemIndex);
																				}}
																			>
																				read more...
																			</div>
																		</>
																	) : (
																		<Text style={{ fontStyle: "italic" }}>
																			No Record
																		</Text>
																	)}
																</Stack.Item>
															</Stack>

															<Stack horizontal styles={rowStyles}>
																<Stack.Item styles={cellStyles}>
																	<Text
																		className={classNames.itemCategory}
																		variant="medium"
																		nowrap
																		block
																	>
																		Education
																	</Text>
																</Stack.Item>
																<Stack.Item styles={descriptionStyles}>
																	<Text variant="medium" block>
																		{item.education[0] ? (
																			item.education.map((record, index) => {
																				if (!record.degree) {
																					return (
																						<div
																							key={`candidate-${item.id}-education-${index}`}
																						>
																							{record.college},{" "}
																							{record.yearRange}
																						</div>
																					);
																				} else {
																					return (
																						<div
																							key={`candidate-${item.id}-education-${index}`}
																						>
																							{record.degree} at{" "}
																							{record.college},{" "}
																							{record.yearRange}
																						</div>
																					);
																				}
																			})
																		) : (
																			<Text style={{ fontStyle: "italic" }}>
																				No Record
																			</Text>
																		)}
																	</Text>
																</Stack.Item>
															</Stack>
														</Stack>
													</CardPreview>
													{/* <CardFooter>
											<Button>test</Button>
										</CardFooter> */}
												</Card>
											))}
										</>
									) : null}
								</div>
							)}
						</div>

						<CandidatesDetailDrawer
							isDetailDrawerOpen={isDetailDrawerOpen}
							setIsDetailDrawerOpen={setIsDetailDrawerOpen}
							selectedIntID={selectedCandidateID}
							setSelectedIntID={setSelectedCandidateID}
							setIsSearchPanelVisible={setIsSearchPanelVisible}
							selectedCandidateDetails={selectedCandidateDetails}
							deleteCandidate={deleteCandidate}
							handleDeleteConfirm={handleDeleteConfirm}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
