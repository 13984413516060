import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import {
	makeStyles,
	shorthands,
	Avatar,
	Body1,
	Button,
	Caption1,
	Card,
	CardHeader,
	CardPreview,
	CardFooter,
	Divider,
	Field,
	Input,
	Text,
	Textarea,
	Toolbar,
	ToolbarButton,
	ToolbarDivider,
	ToolbarToggleButton,
	Tooltip,
	Dialog,
	DialogTrigger,
	DialogSurface,
	DialogTitle,
	DialogBody,
	DialogActions,
	DialogContent,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerHeaderTitle,
	OverlayDrawer,
	Subtitle1,
	Subtitle2,
	Combobox,
	LabelProps,
	Option,
	Popover,
	PopoverTrigger,
	PopoverSurface,
	Radio,
	RadioGroup,
	Spinner,
} from "@fluentui/react-components";
import {
	bundleIcon,
	DeleteRegular,
	DeleteFilled,
	EditRegular,
	EditFilled,
	SendRegular,
	SendFilled,
	CommentRegular,
	DismissRegular,
	TextUnderlineRegular,
	TextBoldRegular,
	TextItalicRegular,
	HighlightFilled,
	Dismiss24Regular,
	Person16Filled,
	Clock16Regular,
	Star16Regular,
	CommentEdit16Regular,
	TaskListSquareLtr16Regular,
	MoreHorizontalRegular,
	AddCircleRegular,
} from "@fluentui/react-icons";
import "../../styles/Candidates.css";
import { formattedDate } from "../../utils/dateUtils";
import { INewComment, Comment } from "../../models/Comment";
import { candidateOverallRating } from "../../constants/dropdownOptions";

const useStyles = makeStyles({
	card: {
		...shorthands.margin("auto"),
		width: "100%",
		maxWidth: "100%",
	},
	cardFooter: {
		display: "flex",
		justifyContent: "space-between",
	},
	commentDropdown: {
		maxWidth: "300px",
	},
	popoverRoot: {
		...shorthands.padding(0),
	},
	popoverDiv: {
		display: "flex",
		flexDirection: "column",
	},
	popoverEditButton: {
		display: "flex",
		justifyContent: "flex-start",
	},
	loadingOverlay: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(255, 255, 255, 0.5)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 1000,
	},
});

export const CandidatesComment = ({ ...props }) => {
	const { selectedIntID, candidateAnalysis } = props;
	const styles = useStyles();
	const queryClient = useQueryClient();
	const Edit = bundleIcon(EditFilled, EditRegular);
	const Delete = bundleIcon(DeleteFilled, DeleteRegular);
	const Send = bundleIcon(SendFilled, SendRegular);

	const [commentList, setCommentList] = useState<Comment[]>([]);
	const [newInput, setNewInput] = useState("");
	const { instance } = useMsal();
	let activeAccount: any;

	if (instance) {
		activeAccount = instance.getActiveAccount();
	}
	const [newCommentRequests, setNewCommentRequests] = useState<INewComment>({
		comment: "",
		commenter: `${activeAccount?.name}`,
		rating: undefined,
		interviewDate: undefined,
		email: `${activeAccount?.username}`,
	});
	const [obsoleteCommentID, setObsoleteCommentID] = useState<any>(null);
	const [obsoleteComment, setObsoleteComment] = useState<any>("");

	const [isPopoverVisible, setIsPopoverVisible] = useState(false);

	const [showAddCommentForm, setShowAddCommentForm] = useState(false);
	const [isEditable, setIsEditable] = useState(false);
	const [editedComment, setEditedComment] = useState("");
	const [editedCommentId, setEditedCommentId] = useState<number | null>(null);
	const [editCommentRequests, setEditCommentRequests] = useState<INewComment>({
		comment: "",
		commenter: `${activeAccount?.name}`,
		rating: undefined,
		interviewDate: undefined,
		email: `${activeAccount?.username}`,
	});
	const [originalComment, setOriginalComment] = useState<INewComment>({
		comment: "",
		commenter: `${activeAccount?.name}`,
		rating: undefined,
		interviewDate: undefined,
		email: `${activeAccount?.username}`,
	});

	const currentDate = new Date().toISOString().split("T")[0];
	const [defaultDateInput, setDefaultDateInput] = useState<string>("");
	const [dateRadioInput, setDateRadioInput] = useState<string | undefined>(
		undefined
	);
	const [editDateRadioInput, setEditDateRadioInput] = useState<
		string | undefined
	>(undefined);

	const { isLoading, error, data } = useQuery(
		["comments"],
		() =>
			axios
				.get(`/candidates/${selectedIntID}/comments`)
				.then((response) => response.data),
		{
			enabled: selectedIntID !== undefined,
			onSuccess: (data) => {
				setCommentList(data.content);
			},
		}
	);

	const handleCommentInput = (
		value: string | undefined,
		condition: string,
		field: keyof INewComment
	) => {
		if (condition === "add") {
			setNewCommentRequests((prevState) => ({
				...prevState,
				[field]: value,
			}));
		}
		if (condition === "edit") {
			setEditCommentRequests((prevState) => ({
				...prevState,
				[field]: value,
			}));
		}
	};

	const [isAddingComment, setIsAddingComment] = useState(false);
	const [isEditingComment, setIsEditingComment] = useState(false);

	const addNewComment = useMutation({
		mutationFn: (newComment: INewComment) => {
			return axios
				.post(`/candidates/${selectedIntID}/comments`, newComment, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				})
				.then((response) => response.data);
		},
		onSuccess: (data: INewComment) => {
			console.log("add comment useMutation", data);
			queryClient.invalidateQueries({ queryKey: ["comments"] });
		},
		onError: (error) => {
			console.error(error);
		},
	});

	const handleEditComment = (e: any, item: Comment) => {
		setIsEditable(true);
		setEditedCommentId(item.CommentId);
		setEditCommentRequests({
			comment: item.Comment,
			commenter: item.Commenter,
			rating: item.Rating ? item.Rating : undefined,
			interviewDate: item.InterviewDate ? item.InterviewDate : undefined,
			email: item.CommenterEmail,
		});
		setOriginalComment({
			comment: item.Comment,
			commenter: item.Commenter,
			rating: item.Rating ? item.Rating : undefined,
			interviewDate: item.InterviewDate ? item.InterviewDate : undefined,
			email: item.CommenterEmail,
		});
		setIsPopoverVisible(false);
	};

	const editComment = useMutation({
		mutationFn: (editedComment: INewComment) => {
			return axios
				.put(
					`/candidates/${selectedIntID}/comments/${editedCommentId}`,
					editedComment,
					{
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
					}
				)
				.then((response) => response.data);
		},
		onSuccess: (data: INewComment) => {
			console.log("edit comment useMutation", data);

			queryClient.invalidateQueries({ queryKey: ["comments"] });
		},
		onError: (error) => {
			console.error(error);
		},
	});

	const handleDeleteComment = (id: number) => {
		// const filtered = commentList.filter((item: any) =>
		//   item["CommentId"].includes(`${obsoleteCommentID}`)
		// );
		setObsoleteCommentID(id);
		const filtered = commentList.filter((comment) => comment.CommentId === id);
		setObsoleteComment(filtered[0].Comment);
		console.log("obsolete data filter", obsoleteComment);
	};

	const deleteComment = useMutation({
		mutationFn: () => {
			return axios
				.delete(`/candidates/${selectedIntID}/comments/${obsoleteCommentID}`, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				})
				.then((response) => response.data);
		},
		onSuccess: (data: INewComment) => {
			console.log("add comment useMutation", data);
			queryClient.invalidateQueries({ queryKey: ["comments"] });
			setObsoleteCommentID(null);
		},
		onError: (error) => {
			console.error(error);
		},
	});
	const onSubmitComment = async (condition: string) => {
		if (condition === "add") {
			if (
				!newCommentRequests.comment ||
				newCommentRequests.comment.trim() === ""
			) {
				return;
			} else {
				setIsAddingComment(true);
				addNewComment.mutate(newCommentRequests, {
					onSettled: (data, error) => {
						if (!error) {
							setTimeout(() => {
								setIsAddingComment(false);
								setNewCommentRequests({
									comment: "",
									commenter: `${activeAccount?.name}`,
									rating: "",
									interviewDate: undefined,
									email: `${activeAccount?.username}`,
								});
								setDefaultDateInput("");
								setDateRadioInput(undefined);
							}, 1000);
						} else {
							setIsAddingComment(false);
						}
					},
				});
			}
		} else if (condition === "edit") {
			if (
				!editCommentRequests.comment ||
				editCommentRequests.comment.trim() === ""
			) {
				return;
			} else {
				setIsEditingComment(true);
				editComment.mutate(editCommentRequests, {
					onSettled: (data, error) => {
						if (!error) {
							setTimeout(() => {
								setIsEditingComment(false);
								setIsEditable(false);
							}, 1000);
						} else {
							setIsEditingComment(false);
						}
					},
				});
			}
		}
	};

	const onKeyDown = (
		condition: string
	): React.KeyboardEventHandler<HTMLTextAreaElement> => {
		return (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
			if (e.shiftKey) return;

			if (e.key === "Enter") {
				e.preventDefault();
				console.log(
					`test onkeydown ${condition}`,
					condition === "add" ? newCommentRequests : editCommentRequests
				);
				onSubmitComment(condition);
			}
		};
	};

	const newTextareaRef = useRef<HTMLTextAreaElement | null>(null);
	const editTextareaRef = useRef<HTMLTextAreaElement | null>(null);

	useEffect(() => {
		if (newTextareaRef.current) {
			newTextareaRef.current.focus();
		}
	}, [showAddCommentForm]);

	useEffect(() => {
		const textarea = newTextareaRef.current;

		if (textarea) {
			const cursorPosition = textarea.selectionStart;

			textarea.style.height = "auto";
			textarea.style.height = `${Math.min(textarea.scrollHeight, 132)}px`;

			textarea.setSelectionRange(cursorPosition, cursorPosition);
		}
	}, [newCommentRequests.comment]);

	useEffect(() => {
		if (isEditable && editTextareaRef.current) {
			const textarea = editTextareaRef.current;

			textarea.focus();

			const length = textarea.value.length;
			textarea.setSelectionRange(length, length);
		}
	}, [isEditable, editCommentRequests.comment]);

	const changeInterviewDate = useCallback(
		(condition: string, option: string) => {
			const isAdd = condition === "add";

			const dateInput = option === "Today" ? currentDate : "";
			const radioInput = option === "Today" ? "Today" : "Clear";

			if (isAdd) {
				console.log("add");
				setDefaultDateInput(dateInput);
				setDateRadioInput(radioInput);
				handleCommentInput(dateInput || undefined, "add", "interviewDate");
			} else {
				console.log("edit");
				setEditCommentRequests((prevState) => ({
					...prevState,
					["interviewDate"]: dateInput,
				}));
				setEditDateRadioInput(radioInput);
				handleCommentInput(dateInput || undefined, "edit", "interviewDate");
			}
		},
		[currentDate]
	);

	// console.log("iscommentdraweropen", isCommentDrawerOpen);
	// console.log("defaultDateInput", defaultDateInput);
	// console.log("dateRadioInput", dateRadioInput);
	// console.log("newCommentRequests", newCommentRequests);
	// console.log("editCommentRequests", editCommentRequests);
	// console.log("editDateRadioInput", editDateRadioInput);

	const textareaRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		const textarea = textareaRef.current;

		if (textarea) {
			textarea.style.height = "auto";
			textarea.style.height = `${Math.min(textarea.scrollHeight, 240)}px`;
		}
	}, [newCommentRequests.comment]);

	const handleCancelClick = (condition: string) => {
		let hasUnsavedChanges = false;

		if (condition === "add") {
			hasUnsavedChanges = !!(
				(newCommentRequests.interviewDate &&
					newCommentRequests.interviewDate !== defaultDateInput) ||
				(newCommentRequests.rating && newCommentRequests.rating !== "") ||
				(newCommentRequests.comment && newCommentRequests.comment.trim() !== "")
			);
		} else if (condition === "edit") {
			hasUnsavedChanges = !!(
				(editCommentRequests.interviewDate &&
					editCommentRequests.interviewDate !==
						originalComment.interviewDate) ||
				(editCommentRequests.rating &&
					editCommentRequests.rating !== originalComment.rating) ||
				(editCommentRequests.comment &&
					editCommentRequests.comment.trim() !== originalComment.comment.trim())
			);
		}

		if (hasUnsavedChanges) {
			const confirmCancel = window.confirm(
				"You have unsaved changes. Are you sure you want to discard them?"
			);

			if (confirmCancel) {
				if (condition === "add") {
					setNewCommentRequests({
						comment: "",
						commenter: `${activeAccount?.name}`,
						rating: "",
						interviewDate: undefined,
						email: `${activeAccount?.username}`,
					});
					setDefaultDateInput("");
					setDateRadioInput("");
					setShowAddCommentForm(false);
				} else if (condition === "edit") {
					setIsEditable(false);
				}
			}
		} else {
			if (condition === "add") {
				setShowAddCommentForm(false);
			} else if (condition === "edit") {
				setIsEditable(false);
			}
		}
	};

	return (
		<>
			<div className="candidates-comment-container">
				<>
					<div>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Subtitle1>Comment(s) </Subtitle1>
							<Button appearance="transparent" icon={<CommentRegular />}>
								{commentList.length}
							</Button>
						</div>

						<Divider style={{ flexGrow: 0 }} />
					</div>

					<div className="comment-input-container">
						{showAddCommentForm ? (
							<>
								<br />{" "}
								<Card className={styles.card}>
									{isAddingComment && (
										<div className={styles.loadingOverlay}>
											<Spinner label="Loading..." />
										</div>
									)}
									<div className="new-comment-card-body">
										<div className="new-avatar-container">
											<Avatar name={activeAccount?.name} />
										</div>
										<div className="comment-container-right">
											<div>
												<Field
													label={{
														children: (
															<>
																<CommentEdit16Regular />
																Comment
															</>
														),
														className: "comment-field-label",
													}}
													className="new-comment-subtitle-label"
													required
												>
													<Textarea
														appearance="filled-darker"
														placeholder="Type here..."
														resize="vertical"
														ref={newTextareaRef}
														onKeyDown={onKeyDown("add")}
														value={newCommentRequests.comment}
														onChange={(e) =>
															handleCommentInput(
																e.target.value,
																"add",
																"comment"
															)
														}
													/>
												</Field>
											</div>
											<div>
												<Field
													label={{
														children: (
															<>
																<Clock16Regular />
																Interview Date
															</>
														),
														className: "comment-field-label",
													}}
													className="new-comment-subtitle-label"
												>
													<span className="comment-subtitle-label interview-date">
														<Input
															type="date"
															appearance="filled-darker"
															className={styles.commentDropdown}
															placeholder="Select Interview Date"
															value={defaultDateInput}
															onChange={(e) => {
																setDefaultDateInput(e.target.value);
																setDateRadioInput(
																	e.target.value === currentDate ? "Today" : ""
																);
																handleCommentInput(
																	e.target.value,
																	"add",
																	"interviewDate"
																);
																console.log("e.target.value", e.target.value);
																console.log("currentDate", currentDate);
															}}
														/>

														<RadioGroup className="interview-date-container">
															<Radio
																label="Today"
																value="Today"
																checked={dateRadioInput === "Today"}
																onChange={() =>
																	changeInterviewDate("add", "Today")
																}
															/>
															<Radio
																label="Clear Date"
																value="Clear"
																checked={dateRadioInput === "Clear"}
																onChange={() =>
																	changeInterviewDate("add", "Clear")
																}
															/>
														</RadioGroup>
													</span>
												</Field>
											</div>
										</div>
									</div>

									<CardFooter className={styles.cardFooter}>
										<Button
											appearance="outline"
											style={{ color: "#bdbdbd" }}
											onClick={() => handleCancelClick("add")}
										>
											Cancel
										</Button>
										<Button
											appearance="primary"
											onClick={() => onSubmitComment("add")}
										>
											Submit
										</Button>
									</CardFooter>
								</Card>
							</>
						) : (
							<>
								<Button
									icon={<AddCircleRegular />}
									onClick={() => setShowAddCommentForm(true)}
									appearance="primary"
								>
									Add Comments
								</Button>
							</>
						)}
					</div>
					<br />
					<div
						className="candidates-search candidates-detail-page-content candidates-comments detail-page-items"
						style={{
							display: "flex",
							flexDirection: "column-reverse",
							rowGap: "12px",
						}}
					>
						{commentList.map((item, index) => (
							<div>
								<div>
									<Card key={index} className="comment-card">
										{isEditingComment && item.CommentId === editedCommentId && (
											<div className={styles.loadingOverlay}>
												<Spinner label="Loading..." />
											</div>
										)}
										<CardHeader
											image={<Avatar name={item.Commenter} />}
											header={
												<Body1>
													<b style={{ color: "var(--jardines-80)" }}>
														{item.Commenter}
													</b>
												</Body1>
											}
											description={
												item.CommentDatetime === item.EditDatetime ? (
													<Caption1 style={{ color: "#808080" }}>
														Posted on {formattedDate(item.CommentDatetime)}
													</Caption1>
												) : (
													<div className="comment-timestamp">
														<Caption1 style={{ color: "#808080" }}>
															Edited {formattedDate(item.EditDatetime)}
														</Caption1>
													</div>
												)
											}
											action={
												<>
													{activeAccount?.username === item.CommenterEmail && (
														<Popover
															trapFocus
															positioning={{
																position: "after",
																align: "bottom",
															}}
														>
															<PopoverTrigger disableButtonEnhancement>
																<Button
																	icon={<MoreHorizontalRegular />}
																	appearance="subtle"
																	onClick={() => setIsPopoverVisible(true)}
																/>
															</PopoverTrigger>

															<PopoverSurface className={styles.popoverRoot}>
																<div className={styles.popoverDiv}>
																	{isPopoverVisible ? (
																		<Button
																			className={styles.popoverEditButton}
																			icon={<Edit />}
																			appearance="subtle"
																			onClick={(e) =>
																				handleEditComment(e, item)
																			}
																		>
																			Edit
																		</Button>
																	) : null}
																	<Dialog modalType="alert">
																		<DialogTrigger disableButtonEnhancement>
																			{isPopoverVisible ? (
																				<Button
																					icon={<Delete />}
																					appearance="subtle"
																					onClick={() => {
																						handleDeleteComment(item.CommentId);
																						setIsPopoverVisible(false);
																					}}
																				>
																					Delete Comment
																				</Button>
																			) : null}
																		</DialogTrigger>

																		<DialogSurface>
																			<DialogBody>
																				<DialogContent>
																					<p>
																						The delete action cannot be
																						reverted.
																					</p>
																					<p>
																						Are you sure you would like to
																						delete this comment?
																					</p>
																				</DialogContent>

																				<DialogActions>
																					<DialogTrigger
																						disableButtonEnhancement
																					>
																						<Button
																							appearance="secondary"
																							onClick={() =>
																								setIsPopoverVisible(false)
																							}
																						>
																							Close
																						</Button>
																					</DialogTrigger>
																					<DialogTrigger>
																						<Button
																							onClick={() =>
																								deleteComment.mutate(
																									obsoleteCommentID
																								)
																							}
																							appearance="primary"
																						>
																							Confirm
																						</Button>
																					</DialogTrigger>
																				</DialogActions>
																			</DialogBody>
																		</DialogSurface>
																	</Dialog>
																</div>
															</PopoverSurface>
														</Popover>
													)}
												</>
											}
										/>
										{isEditable && item.CommentId === editedCommentId ? (
											<div className="editable-comment-container">
												<div className="new-comment-card-body">
													<div className="new-avatar-container"></div>
													<div className="comment-container-right">
														<div>
															<Field
																label={{
																	children: (
																		<>
																			<CommentEdit16Regular />
																			Comment
																		</>
																	),
																	className: "comment-field-label",
																}}
																className="new-comment-subtitle-label"
																required
															>
																<Textarea
																	appearance="filled-darker"
																	placeholder="Type here..."
																	resize="vertical"
																	ref={editTextareaRef}
																	onKeyDown={onKeyDown("edit")}
																	value={editCommentRequests.comment}
																	onChange={(e) =>
																		handleCommentInput(
																			e.target.value,
																			"edit",
																			"comment"
																		)
																	}
																/>
															</Field>
														</div>
														<div>
															<Field
																label={{
																	children: (
																		<>
																			<Clock16Regular />
																			Interview Date
																		</>
																	),
																	className: "comment-field-label",
																}}
																className="new-comment-subtitle-label"
															>
																<span className="comment-subtitle-label interview-date">
																	<Input
																		type="date"
																		appearance="filled-darker"
																		className={styles.commentDropdown}
																		placeholder="Select Interview Date"
																		value={
																			editCommentRequests.interviewDate || ""
																		}
																		onChange={(e) => {
																			handleCommentInput(
																				e.target.value,
																				"edit",
																				"interviewDate"
																			);
																			console.log(
																				"e.target.value",
																				e.target.value
																			);
																		}}
																	/>

																	<RadioGroup className="interview-date-container">
																		<Radio
																			label="Today"
																			value="Today"
																			checked={editDateRadioInput === "Today"}
																			onChange={() => {
																				changeInterviewDate("edit", "Today");
																			}}
																		/>
																		<Radio
																			label="Clear Date"
																			value="Clear"
																			checked={editDateRadioInput === "Clear"}
																			onChange={() => {
																				changeInterviewDate("edit", "Clear");
																			}}
																		/>
																	</RadioGroup>
																</span>
															</Field>
														</div>
													</div>
												</div>
												<CardFooter className={styles.cardFooter}>
													<Button
														appearance="outline"
														style={{ color: "#bdbdbd" }}
														onClick={() => handleCancelClick("edit")}
													>
														Cancel
													</Button>
													<Button
														appearance="primary"
														onClick={() => onSubmitComment("edit")}
													>
														Submit
													</Button>
												</CardFooter>
											</div>
										) : (
											<CardPreview className="comment-container">
												<div className="comment-container-left"></div>
												<div className="comment-container-right">
													<div>
														<span className="comment-field-label">
															<CommentEdit16Regular />
															<p className="new-comment-subtitle-label">
																Comment
															</p>
														</span>
														<p className="comment-form-content">
															{item.Comment}
														</p>
													</div>
													<div className="comment-profile-container">
														<div>
															<div className="comment-field-label new-comment-subtitle-label">
																<Clock16Regular />
																Interview Date
															</div>
															<div className="interview-date-content">
																<p className="comment-form-content">
																	{" "}
																	{item.InterviewDate
																		? item.InterviewDate
																		: "N/A"}
																</p>
															</div>
														</div>
													</div>
												</div>
											</CardPreview>
										)}
									</Card>
								</div>
							</div>
						))}
					</div>
					<br />
					<br />
					<br />
					<br />
				</>
			</div>
		</>
	);
};
