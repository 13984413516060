import { useState, useEffect, useRef } from "react";
import GaugeChart from "react-gauge-chart";

export function WorkingExperienceGaugeChart(props) {
	const { workingExperience, titleKey } = props;
	const { requestyears, totalyears, relevantyears } = workingExperience;

	let percent;
	let colors = ["#5BE12C", "#C1C0C0"];

	if (relevantyears > requestyears) {
		percent = 1;
	} else if (relevantyears === 0) {
		percent = 0;
		colors = ["#C1C0C0", "#C1C0C0"]; // Set both arcs to the same color when relevantyears is 0
	} else {
		percent = relevantyears / requestyears;
	}

	const relevantArcLength = percent;
	const remainingArcLength = 1 - percent;
	const arcsLength = [relevantArcLength, remainingArcLength];

	const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
	const chartRef = useRef(null);

	useEffect(() => {
		const updateSize = () => {
			if (chartRef.current) {
				const width = chartRef.current.clientWidth;
				setChartSize({ width, height: width / 3 });
			}
		};

		window.addEventListener("resize", updateSize);
		updateSize();

		return () => window.removeEventListener("resize", updateSize);
	}, []);

	return (
		<div className="GaugeChart-Base onecolumn fullsize">
			<div className="GaugeChartChart-inline">
				<div className="GaugeChartChart-title padding-topleft-corner">
					<b>{titleKey}</b>
				</div>
				<div className="GaugeChartChart-body">
					<div className="GaugeChartChart-content" ref={chartRef}>
						{arcsLength && (
							<GaugeChart
								id="gauge-chart5"
								nrOfLevels={5}
								arcsLength={arcsLength}
								colors={colors}
								hideText={true}
								arcPadding={0.02}
								percent={percent}
								width={chartSize.width}
								height={chartSize.height}
							/>
						)}
						<div className="GaugeChartChart-indicator">
							<span className="gauge-request-years">0</span>
							<span className="gauge-request-years">
								<p>{requestyears}</p>
								<p>Request Year(s)</p>
							</span>
						</div>
					</div>

					<div className="GaugeChartChart-legends">
						<div className="legend-item">
							<span
								className="legend-color"
								style={{
									backgroundColor: relevantyears === 0 ? "#C1C0C0" : "#5BE12C",
								}}
							></span>
							<span className="legend-label">
								Relevant Year(s): {relevantyears}
							</span>
						</div>

						<div className="legend-item">
							<span className="legend-label">
								Total Years of Work Experience: {totalyears}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
