import React from "react";

import { makeStyles, Dropdown, Option } from "@fluentui/react-components";

import { PageComponentsProps } from "../models/Candidate"

const useStyles = makeStyles({
	container: {
		position: "absolute",
		right: 0,
		display: "flex",
		fontSize: "12px",
		fontWeight: "bold",
		columnGap: "6px",
		padding: "0 10px",
		alignItems: "center",
	},
	enabled: {
		color: "black",
	},
	disabled: {
		color: "#BDBDBD",
	},
	pageDropdown: {
		minWidth: "50px",
	},
	listbox: {
		width: "100%",
		minWidth: "67px",
		maxWidth: "67px",
	},
});

const ViewItemsPerPage = ({
	searchStatus,
	totalItems,
	itemsPerPage,
	onItemsPerPageChange,
}: PageComponentsProps) => {
	const styles = useStyles();
	const isEnabled = totalItems && searchStatus;

	return (
		<div
			className={`${styles.container} ${
				isEnabled ? styles.enabled : styles.disabled
			}`}
		>
			<span>View</span>

			<Dropdown
				className={styles.pageDropdown}
				aria-labelledby={`page-size-dropdown`}
				listbox={{
					className: styles.listbox,
				}}
				size="small"
				defaultValue="25"
				defaultSelectedOptions={[`${itemsPerPage}`]}
				disabled={!isEnabled}
				onOptionSelect={onItemsPerPageChange}
			>
				<Option>25</Option>
				<Option>50</Option>
				<Option>100</Option>
			</Dropdown>
			<span>Per Page</span>
		</div>
	);
};

export default ViewItemsPerPage;
