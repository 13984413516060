import React, { useState, useEffect, useMemo } from "react";

import {
	TableColumnDefinition,
	createTableColumn,
	TableCellLayout,
	useScrollbarWidth,
	useFluent,
	Button,
	Dialog,
	DialogTrigger,
	DialogSurface,
	DialogTitle,
	DialogBody,
	DialogContent,
	Link,
	DataGridProps,
	makeStyles,
	Text,
	Caption1,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import {
	DataGridBody,
	DataGrid,
	DataGridRow,
	DataGridHeader,
	DataGridCell,
	DataGridHeaderCell,
	RowRenderer,
} from "@fluentui-contrib/react-data-grid-react-window";
import "../../styles/JobOpenings.css";

import { JobOpeningsItem, ReviewItem } from "../../models/JobOpenings";

const useStyles = makeStyles({
	field: {
		fontWeight: 600,
		padding: "0.5rem 0",
	},
});

const columnSizingOptions = {
	title: {
		defaultWidth: 300,
		minWidth: 200,
		idealWidth: 300,
	},
	review: {
		defaultWidth: 270,
		minWidth: 230,
		idealWidth: 270,
	},
	topMatchName: {
		defaultWidth: 230,
		minWidth: 180,
		idealWidth: 230,
	},
	topMatchScore: {
		defaultWidth: 80,
		maxWidth: 80,
		minWidth: 50,
		idealWidth: 80,
	},
	marketSalary: {
		defaultWidth: 170,
		minWidth: 130,
		maxWidth: 170,
		idealWidth: 170,
	},
	minInternalSalary: {
		defaultWidth: 170,
		minWidth: 130,
		maxWidth: 170,
		idealWidth: 170,
	},
	maxInternalSalary: {
		defaultWidth: 170,
		minWidth: 130,
		maxWidth: 170,
		idealWidth: 170,
	},
	postDate: {
		defaultWidth: 170,
		maxWidth: 170,
		idealWidth: 170,
	},
	status: {
		defaultWidth: 170,
		maxWidth: 170,
		idealWidth: 170,
	},
};

export const EmployeeTable = ({ ...props }) => {
	const {
		onEdit,
		onSelectionChange,
		sortedData,
		itemSize,
		tableHeight,
		originalRecords,
		filteredRecords,
		setOriginalRecords,
		setFilteredRecords,
		filterInputForm,
	} = props;
	const styles = useStyles();
	const [jobOpeningsItem, setJobOpeningsItem] = useState<JobOpeningsItem[]>([]);
	// const [sortedData, setSortedData] = useState<IJob[]>([]);
	const [selectedReview, setSelectedReview] = useState<ReviewItem[]>([]);
	const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
	const [reviewOpeningId, setReviewOpeningId] = useState<any>(null);

	const { targetDocument } = useFluent();
	const scrollbarWidth = useScrollbarWidth({ targetDocument });

	useEffect(() => {
		console.log("sortedData", sortedData);

		if (Array.isArray(sortedData) && sortedData.length > 0) {
			const JobOpeningsItem = sortedData.map((item) => {
				// console.log("Current item:", item);
				const topScore = Math.max(
					...item.review.map((review: any) => Number(review.score) || 0)
				);
				return {
					openingId: item.id,
					title: {
						label: item.title ?? "Untitled",
						// icon: <OpenRegular />,
						id: item.id ?? null,
					},
					review: {
						label: item.review
							? item.review.map((review: any) => ({
									id: review.candidateId,
									recommendation: review.recommendation,
									score: review.score,
									name: review.name,
							  }))
							: [],
						length: item.review?.length || 0,
					},
					marketSalary: {
						amount: item.marketSalary
							? JSON.stringify(item.marketSalary)
							: null,
					},
					minInternalSalary: {
						amount: item.minInternalSalary
							? JSON.stringify(item.minInternalSalary)
							: null,
					},
					maxInternalSalary: {
						amount: item.maxInternalSalary
							? JSON.stringify(item.maxInternalSalary)
							: null,
					},
					postDate: { label: item.postDate ?? "No date" },
					status: { label: item.status ?? "Unknown" },
					topScore: { label: topScore ?? "N/A" },
				};
			});

			setOriginalRecords(JobOpeningsItem);
			setFilteredRecords(JobOpeningsItem);
		} else {
			console.error("sortedData is not a valid array:", sortedData);
		}
	}, [sortedData]);

	const handleMatchedRowSelect = (e: any, reviewItem: any, openingId: any) => {
		e.stopPropagation();
		console.log("handleMatchedCandidateSelect", reviewItem);
		setReviewOpeningId(openingId);
		setSelectedReview(reviewItem);
	};

	const handleCandidateClick = (e: any, id: number | undefined) => {
		e.stopPropagation();
		window.open(`candidates/dashboard/${id}`, "_blank");
	};

	const filteredRecommendation = (reviews: any) => {
		const filteredReviews = reviews.filter(
			(item: any) =>
				item.recommendation &&
				item.recommendation !== "Reject" &&
				item.score >= filterInputForm.score
		);
		return filteredReviews;
	};
	const [reviewSortState, setReviewSortState] = useState<
		Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
	>({
		sortColumn: "score",
		sortDirection: "descending",
	});
	const onReviewSortChange: DataGridProps["onSortChange"] = (
		e,
		nextSortState
	) => {
		setReviewSortState(nextSortState);
	};

	const reviewColumns: TableColumnDefinition<ReviewItem>[] = [
		createTableColumn<ReviewItem>({
			columnId: "name",
			compare: (a, b) => {
				return (a.name || "").localeCompare(b.name || "");
			},
			renderHeaderCell: () => {
				return "Name";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout
						style={{ cursor: "pointer" }}
						onClick={(e) => {
							handleCandidateClick(e, item.id);
						}}
					>
						<Link>{item.name}</Link>
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<ReviewItem>({
			columnId: "recommendation",
			compare: (a, b) => {
				return (a.recommendation || "").localeCompare(b.recommendation || "");
			},
			renderHeaderCell: () => {
				return "Recommendation";
			},
			renderCell: (item) => {
				return <TableCellLayout>{item.recommendation}</TableCellLayout>;
			},
		}),
		createTableColumn<ReviewItem>({
			columnId: "score",
			compare: (a, b) => {
				return Number(a.score) - Number(b.score);
			},
			renderHeaderCell: () => {
				return "Score";
			},

			renderCell: (item) => {
				return item.score;
			},
		}),
	];

	const [sortState, setSortState] = useState<
		Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
	>({
		sortColumn: "postDate",
		sortDirection: "descending",
	});
	const onSortChange: DataGridProps["onSortChange"] = (e, nextSortState) => {
		setSortState(nextSortState);
	};

	const columns: TableColumnDefinition<JobOpeningsItem>[] = [
		createTableColumn({
			columnId: "title",
			compare: (a, b) => {
				return (a.title?.label || "").localeCompare(b.title?.label || "");
			},
			renderHeaderCell: () => {
				return "Job Title";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout
						truncate
						// media={item.title.icon}
					>
						<Link
							style={{
								cursor: "pointer",
								fontWeight: "600",
								color: "var(--tag-color)",
							}}
							onClick={(e) => {
								e.stopPropagation();
								console.log("clicked job title", item);
								onEdit(item.title.id);
							}}
						>
							{item.title.label}
						</Link>
					</TableCellLayout>
					// <OpeningModal item={item}/>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "review",
			compare: (a, b) => {
				return a.review.length - b.review.length;
			},
			renderHeaderCell: () => {
				return "Review matched candidate(s)";
			},
			renderCell: (item) => {
				const renderMatchedRow: RowRenderer<ReviewItem> = (
					{ item, rowId },
					style
				) => (
					<DataGridRow<ReviewItem> key={rowId} style={style}>
						{({ renderCell }) => (
							<DataGridCell>{renderCell(item)}</DataGridCell>
						)}
					</DataGridRow>
				);

				return filteredRecommendation(item.review.label).length === 0 ? (
					<TableCellLayout truncate>
						There are currently no matched candidates.
					</TableCellLayout>
				) : (
					<Dialog
						open={item.openingId === reviewOpeningId}
						// onOpenChange={(e) => e.stopPropagation()}
					>
						<DialogTrigger disableButtonEnhancement>
							<TableCellLayout
								truncate
								onClick={(e) =>
									handleMatchedRowSelect(e, item.review, item.openingId)
								}
								style={{ cursor: "pointer" }}
							>
								<Link>
									Click to see a total of{" "}
									{filteredRecommendation(item.review.label).length}{" "}
									candidate(s)
								</Link>
							</TableCellLayout>
						</DialogTrigger>
						<DialogSurface>
							<DialogBody onClick={(e) => e.stopPropagation()}>
								<DialogTitle
									action={
										<DialogTrigger action="close">
											<Button
												appearance="subtle"
												aria-label="close"
												icon={<Dismiss24Regular />}
												onClick={() => setReviewOpeningId(null)}
											/>
										</DialogTrigger>
									}
								>
									{item.title.label}
								</DialogTitle>
								<DialogContent>
									<DataGrid
										// selectionMode="multiselect"
										items={filteredRecommendation(item.review.label)}
										columns={reviewColumns}
										// focusMode="composite"
										sortable
										sortState={reviewSortState}
										onSortChange={onReviewSortChange}
									>
										<DataGridHeader>
											<DataGridRow
												style={{ height: "3rem" }}
												// selectionCell={{
												// 	checkboxIndicator: {
												// 		"aria-label": "Select all rows",
												// 	},
												// }}
											>
												{({ renderHeaderCell }) => (
													<DataGridHeaderCell className={styles.field}>
														{renderHeaderCell()}
													</DataGridHeaderCell>
												)}
											</DataGridRow>
										</DataGridHeader>
										<DataGridBody itemSize={50} height={400}>
											{renderMatchedRow}
										</DataGridBody>
									</DataGrid>
								</DialogContent>
							</DialogBody>
						</DialogSurface>
					</Dialog>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "topMatchName",
			compare: (a, b) => {
				return (a.marketSalary?.amount || "").localeCompare(
					b.marketSalary.amount || ""
				);
			},
			renderHeaderCell: () => {
				return "Top matched candidate(s)";
			},

			renderCell: (item) => {
				// console.log("top matched", item);
				const topMatch = (reviews: any, column: string) => {
					const maxScore = Math.max(
						...reviews.map((candidate: any) => candidate.score)
					);
					const topMatchNames = reviews
						.filter((candidate: any) => candidate.score === maxScore)
						.map((i: any) => i.name);
					if (column === "name") {
						// console.log(topMatchNames);
						return topMatchNames;
					}
					if (column === "score") {
						return maxScore;
					}
				};
				return (
					<>
						{item.review.label.length === 0 ||
						filteredRecommendation(item.review.label).length === 0 ? (
							<div className="table-na">N/A</div>
						) : (
							<div
								className="top-matched-candidate"
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
									paddingRight: "16px",
									overflow: "hidden",
								}}
							>
								<span style={{ fontWeight: "600", whiteSpace: "normal" }}>
									<ul
										style={{
											display: "flex",
											flexDirection: "column",
											margin: "0",
											padding: "0",
											overflow: "hidden",
										}}
									>
										{topMatch(item.review.label, "name")
											.slice(0, 3)
											.map((i: any, index: number) => {
												return <li key={`matched-${index}`}>{i}</li>;
											})}
										{topMatch(item.review.label, "name").length > 3 && (
											<Link
												style={{
													fontWeight: "600",
													marginTop: "6px",
												}}
												onClick={(e) => {
													e.stopPropagation();
													handleMatchedRowSelect(
														e,
														item.review,
														item.openingId
													);
												}}
											>
												+ {topMatch(item.review.label, "name").length - 3}{" "}
												more...
											</Link>
										)}
									</ul>
								</span>
							</div>
						)}
					</>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "topMatchScore",
			compare: (a, b) => {
				return (a.marketSalary?.amount || "").localeCompare(
					b.marketSalary.amount || ""
				);
			},
			renderHeaderCell: () => {
				return "Score";
			},

			renderCell: (item) => {
				const topMatch = (reviews: any, column: string) => {
					const maxScore = Math.max(
						...reviews.map((candidate: any) => candidate.score)
					);
					const topMatchNames = reviews
						.filter((candidate: any) => candidate.score === maxScore)
						.map((i: any) => i.scorename);
					if (column === "name") {
						return topMatchNames;
					}
					if (column === "score") {
						return maxScore;
					}
				};
				return (
					<>
						{item.review.label.length === 0 ||
						filteredRecommendation(item.review.label).length === 0 ? (
							<div style={{ display: "flex", justifyContent: "center" }}>
								<p>
									<i>N/A</i>
								</p>
							</div>
						) : (
							<div
								className="top-matched-candidate-score"
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
									paddingRight: "16px",
								}}
							>
								<span style={{ fontWeight: "600" }}>
									{topMatch(item.review.label, "score")}
								</span>
							</div>
						)}
					</>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "marketSalary",
			compare: (a, b) => {
				return (a.marketSalary?.amount || "").localeCompare(
					b.marketSalary.amount || ""
				);
			},
			renderHeaderCell: () => {
				return "Market Salary";
			},

			renderCell: (item) => {
				return (
					<TableCellLayout>
						{item.marketSalary.amount ? (
							new Intl.NumberFormat("en-HK", {
								style: "currency",
								currency: item.title.label.toLowerCase().includes("indonesia")
									? "IDR"
									: "HKD",
								currencyDisplay: "symbol",
							})
								.format(Number(item.marketSalary.amount))
								.replace("HK", "HKD ")
						) : (
							<i>
								<Caption1>Not Provided</Caption1>
							</i>
						)}
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "minInternalSalary",
			compare: (a, b) => {
				return (a.minInternalSalary?.amount || "").localeCompare(
					b.minInternalSalary.amount || ""
				);
			},
			renderHeaderCell: () => {
				return "Min Internal Salary";
			},

			renderCell: (item) => {
				return (
					<TableCellLayout>
						{item.minInternalSalary.amount ? (
							new Intl.NumberFormat("en-HK", {
								style: "currency",
								currency: item.title.label.toLowerCase().includes("indonesia")
									? "IDR"
									: "HKD",
								currencyDisplay: "symbol",
							})
								.format(Number(item.minInternalSalary.amount))
								.replace("HK", "HKD ")
						) : (
							<i>
								<Caption1>Not Provided</Caption1>
							</i>
						)}
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "maxInternalSalary",
			compare: (a, b) => {
				return (a.maxInternalSalary?.amount || "").localeCompare(
					b.maxInternalSalary.amount || ""
				);
			},
			renderHeaderCell: () => {
				return "Max Internal Salary";
			},

			renderCell: (item) => {
				return (
					<TableCellLayout>
						{item.maxInternalSalary.amount ? (
							new Intl.NumberFormat("en-HK", {
								style: "currency",
								currency: item.title.label.toLowerCase().includes("indonesia")
									? "IDR"
									: "HKD",
								currencyDisplay: "symbol",
							})
								.format(Number(item.maxInternalSalary.amount))
								.replace("HK", "HKD ")
						) : (
							<i>
								<Caption1>Not Provided</Caption1>
							</i>
						)}
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "postDate",
			compare: (a, b) => {
				return (a.postDate?.label || "").localeCompare(b.postDate?.label || "");
			},
			renderHeaderCell: () => {
				return "Post Date";
			},

			renderCell: (item) => {
				return item.postDate.label;
			},
		}),
		createTableColumn<JobOpeningsItem>({
			columnId: "status",
			// compare: (a, b) => {
			// 	return (a.status?.label || "").localeCompare(b.status?.label || "");
			// },
			renderHeaderCell: () => {
				return "Status";
			},

			renderCell: (item) => {
				return item.status.label;
			},
		}),
	];

	const renderOpeningsRow: RowRenderer<JobOpeningsItem> = (
		{ item, rowId },
		style
	) => (
		<DataGridRow<JobOpeningsItem> key={rowId} style={style}>
			{({ renderCell }) => (
				<DataGridCell className="data-grid-cell">
					{renderCell(item)}
				</DataGridCell>
			)}
		</DataGridRow>
	);

	return (
		<DataGrid
			size="small"
			items={filteredRecords}
			columns={columns}
			focusMode="cell"
			sortable
			sortState={sortState}
			onSortChange={onSortChange}
			selectionMode="multiselect"
			onSelectionChange={(e, data) => {
				onSelectionChange(data);
				// console.log("onSelectionChange", data);
			}}
			getRowId={(item) => item.openingId}
			selectionAppearance="neutral"
			columnSizingOptions={columnSizingOptions}
			resizableColumns
			style={{
				backgroundColor: "#FFFFFF",
				border: "1px solid #E0E0E0",
				borderRadius: "4px",
				boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.10)",
			}}
		>
			<DataGridHeader>
				<DataGridRow>
					{({ renderHeaderCell }) => (
						<DataGridHeaderCell className={styles.field}>
							{renderHeaderCell()}
						</DataGridHeaderCell>
					)}
				</DataGridRow>
			</DataGridHeader>
			{filteredRecords.length === 0 ? (
				<div
					style={{
						padding: "24px 0",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Text italic>
						No results found. Please try adjusting your filters or check back
						later.
					</Text>
				</div>
			) : (
				<DataGridBody<JobOpeningsItem> itemSize={itemSize} height={tableHeight}>
					{renderOpeningsRow}
				</DataGridBody>
			)}
		</DataGrid>
	);
};
