import React from "react";
import {
	Dialog,
	DialogSurface,
	DialogBody,
	DialogContent,
	DialogActions,
	Button,
	DialogTrigger,
	DialogTitle,
} from "@fluentui/react-components";

import { DismissRegular } from "@fluentui/react-icons";

interface DeleteCandidateDialogProps {
	deleteButtonLabel: string;
	candidatesData?: any[];
	selectionArr: number[];
	deleteCandidate: any;
	handleDeleteConfirm: () => void;
	deleteStatus: string | undefined;
}

const DeleteCandidateDialog = ({
	deleteButtonLabel,
	candidatesData,
	selectionArr,
	deleteCandidate,
	handleDeleteConfirm,
	deleteStatus,
}: DeleteCandidateDialogProps) => {
	const getSelectedCandidateNames = () => {
		if (!candidatesData) {
			return [];
		}

		const selectedArr = Array.isArray(selectionArr)
			? selectionArr
			: [selectionArr];

		if (candidatesData.length > 1) {
			return candidatesData
				.filter((candidate) => selectedArr.includes(candidate.id))
				.map((candidate) => candidate.name);
		}
		if (candidatesData.length === 1) {
			return candidatesData[0].name;
		}
	};

	const selectedCandidateNames = getSelectedCandidateNames();
	// console.log("delete modal", candidatesData);
	// console.log("modal selectionArr", selectionArr);
	// console.log("deleteStatus", deleteStatus);

	return (
		<>
			<Dialog modalType="alert">
				<DialogTrigger disableButtonEnhancement>
					<Button
						icon={<DismissRegular />}
						// appearance="subtle"
						disabled={selectionArr.length === 0}
						style={{
							backgroundColor: selectionArr.length === 0 ? "" : "#f5574b",
							color: selectionArr.length === 0 ? "" : "#ffffff",
						}}
					>
						{deleteButtonLabel}
					</Button>
				</DialogTrigger>

				<DialogSurface>
					<DialogBody>
						<DialogTitle>
							Are you sure to delete the following candidate(s)?
						</DialogTitle>
						<DialogContent>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									padding: "24px 48px",
								}}
							>
								<div
									style={{
										backgroundColor: "#F0F0F0",

										// fontWeight: "bold",
										width: "100%",
									}}
								>
									<ol>
										{candidatesData && candidatesData.length > 1
											? selectedCandidateNames.map(
													(name: string, index: number) => (
														<li key={index}>{name}</li>
													)
											  )
											: selectedCandidateNames}
									</ol>
								</div>
							</div>
							<p>The delete action cannot be reverted.</p>
							<p>It will remove all the candidate's data from the system.</p>
						</DialogContent>

						<DialogActions>
							<DialogTrigger disableButtonEnhancement>
								<Button appearance="secondary">Close</Button>
							</DialogTrigger>
							<DialogTrigger>
								<Button
									style={{
										backgroundColor: "#f5574b",
										color: "#ffffff",
									}}
									// onClick={() => deleteCandidate.mutate(selectedIntID)}
									onClick={() => {
										console.log("selectionArr", selectionArr);
										deleteCandidate.mutate(selectionArr);
									}}
								>
									Confirm
								</Button>
							</DialogTrigger>
						</DialogActions>
					</DialogBody>
				</DialogSurface>
			</Dialog>

			{deleteStatus && (
				<Dialog open={deleteStatus ? true : false}>
					<DialogSurface>
						{deleteStatus === "success" ? (
							<DialogBody>
								<DialogContent>
									<p> The candidate has been deleted successfully.</p>
									<p> Back to candidate search results.</p>
								</DialogContent>
								<DialogActions>
									<Button appearance="primary" onClick={handleDeleteConfirm}>
										Got it
									</Button>
								</DialogActions>
							</DialogBody>
						) : (
							<DialogBody>
								<DialogContent>
									<p> An unexpected error occured.</p>
									<p> Please try again or contact IT support.</p>
								</DialogContent>
								<DialogActions>
									<Button appearance="primary" onClick={handleDeleteConfirm}>
										Got it
									</Button>
								</DialogActions>
							</DialogBody>
						)}
					</DialogSurface>
				</Dialog>
			)}
		</>
	);
};

export default DeleteCandidateDialog;
