import React, {
	useState,
	useEffect,
	RefObject,
	ChangeEvent,
	useRef,
	SyntheticEvent,
} from "react";
import { useMsal } from "@azure/msal-react";
import {
	makeStyles,
	Field,
	Button,
	Input,
	Combobox,
	Option,
	Subtitle1,
	TableColumnDefinition,
	createTableColumn,
	TableCellLayout,
	Link,
	DataGridProps,
	Accordion,
	AccordionHeader,
	AccordionItem,
	AccordionPanel,
	Divider,
	useScrollbarWidth,
	useFluent,
	Text,
	Tag,
	TagGroup,
	Badge,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import {
	DataGridBody,
	DataGrid,
	DataGridRow,
	DataGridHeader,
	DataGridCell,
	DataGridHeaderCell,
	RowRenderer,
} from "@fluentui-contrib/react-data-grid-react-window";
import {
	SearchRegular,
	Alert20Filled,
	CalendarRegular,
	Filter20Regular,
	KeyboardRegular,
	PersonRegular,
	FilterDismissRegular,
	ArrowClockwise20Regular,
} from "@fluentui/react-icons";
import { setDocumentTitle } from "../../utils/documentTitle";

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import LoadingSpinner from "../../components/LoadingSpinner";
import Loading from "../../components/Loading";
import "../../styles/TopMatches.css";

import PaginationContainer from "../../components/PaginationContainer";
import { formattedDate } from "../../utils/dateUtils";
import { TopMatch, TopMatchesList } from "../../models/TopMatches";

const useStyles = makeStyles({
	// filterAccordion: {  },
	filterAccordionHeader: {
		display: "flex",
		alignItems: "center",
		flexShrink: 0,
		columnGap: "8px",
		// height: "32px",
		color: "var(--jardines-80)",
	},
	filterAccordionHeaderContent: {
		display: "flex",
		alignItems: "center",
		flexShrink: 0,
		columnGap: "8px",
		height: "32px",
		color: "var(--jardines-80)",
		fontWeight: 600,
	},
	filterInputContainer: {},
	filterInputDateContainer: {
		display: "flex",
		flexDirection: "column",
		rowGap: "10px",
	},
	filterInputDate: {
		display: "flex",
		columnGap: "10px",
		justifyContent: "space-between",
	},
	filterActionButtons: {
		display: "flex",
		columnGap: "10px",
		alignItems: "end",
	},
	filterLabel: {
		">.fui-Field__label": {
			fontSize: "12px",
		},
	},
	filterInput: {
		">.fui-Input__input": {
			width: "250px",
		},
	},

	tableHeader: {
		">.fui-DataGridRow": {
			paddingLeft: "44px",
		},
	},
	tableHeaderCell: {
		fontWeight: 600,
		padding: "0.5rem 0",
	},
	dismissButton: {
		padding: 0,
	},
	searchOpeningInput: {
		width: "300px",
	},
	searchNameInput: {
		width: "250px",
	},
	tableRow: {
		paddingLeft: "44px",
	},
	tableCell: {
		// margin: "0 8px",
	},
	tag: {
		backgroundColor: "var(--tag-bg-color)",
		color: "var(--tag-color)",
		fontWeight: "bold",
		border: "1px solid var(--tag-color)",
	},
	badge: {
		backgroundColor: "var(--tag-bg-color)",
		color: "var(--tag-color)",
		fontWeight: "bold",
		border: "1px var(--tag-color)",
	},
	datePicker: {
		">.fui-CalendarDayGrid__dayButton": {
			backgroundColor: "var(--tag-bg-color) !important",
			color: "#ffffff !important",
			":hover": {
				backgroundColor: "var(--tag-bg-color) !important",
				color: "#ffffff !important",
			},
			":active": {
				backgroundColor: "var(--tag-bg-color) !important",
				color: "#ffffff !important",
			},
		},
	},
});

const columnSizingOptions = {
	candidate_name: {
		defaultWidth: 300,
		minWidth: 300,
	},
	appliedOpening: {
		defaultWidth: 300,
		minWidth: 300,
	},
	assessedOpening: {
		defaultWidth: 280,
		minWidth: 280,
	},
	score: {
		defaultWidth: 80,
		maxWidth: 80,
		minWidth: 50,
		// idealWidth: 170,
	},
};

const TopMatchesReport = () => {
	const styles = useStyles();
	const { instance } = useMsal();
	let activeAccount;
	if (instance) {
		activeAccount = instance.getActiveAccount();
	}

	const [sortedData, setSortedData] = useState<any[]>([]);

	const [originalRecords, setOriginalRecords] = useState<
		TopMatchesList | undefined
	>(undefined);
	const [filteredRecords, setFilteredRecords] = useState<
		TopMatchesList | undefined
	>(undefined);
	const [searchStatus, setSearchStatus] = useState<boolean>(false);

	useEffect(() => {
		setDocumentTitle("Top Matches Report");
	}, []);

	const [tableHeight, setTableHeight] = useState<number>(0);
	const parentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const calculateGridHeight = () => {
			if (parentRef.current) {
				const parentHeight = parentRef.current.offsetHeight;
				setTableHeight(parentHeight * 0.4);
			}
		};

		calculateGridHeight();
		window.addEventListener("resize", calculateGridHeight);

		return () => window.removeEventListener("resize", calculateGridHeight);
	}, []);

	const [itemSize, setItemSize] = useState(50);

	const calculateItemSize = () => {
		const viewportWidth = window.innerWidth;
		return viewportWidth < 1560 ? 100 : 50;
	};

	useEffect(() => {
		const updateItemSize = () => {
			setItemSize(calculateItemSize());
		};

		updateItemSize();

		window.addEventListener("resize", updateItemSize);

		return () => {
			window.removeEventListener("resize", updateItemSize);
		};
	}, []);

	const today = new Date();

	type FilterKeys = "title" | "minScore" | "candidateName";

	interface AppliedFilter {
		field: FilterKeys;
		value: string;
	}

	const [filters, setFilters] = useState({
		dateStart: undefined,
		dateEnd: today,
		title: "",
		minScore: "0",
		candidateName: "",
	});
	const [appliedFilters, setAppliedFilters] = useState<AppliedFilter[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(25);

	const resultStartRange = (currentPage - 1) * itemsPerPage + 1;
	const resultEndRange = Math.min(
		currentPage * itemsPerPage,
		filteredRecords ? filteredRecords.meta.total : 0
	);

	const handleFilterDateChange = (startDate: any, endDate: any) => {
		if (startDate && startDate > endDate) {
			setFilters((prev) => ({
				...prev,
				dateStart: endDate,
				dateEnd: startDate,
			}));
		} else {
			setFilters((prev) => ({
				...prev,
				dateStart: startDate,
				dateEnd: endDate,
			}));
		}
	};

	const clearFilter = () => {
		setFilters({
			dateStart: undefined,
			dateEnd: today,
			title: "",
			minScore: "0",
			candidateName: "",
		});
		setAppliedFilters([]);
		setCurrentPage(1);
		refetch();
	};

	const buildQueryString = (filters: any) => {
		const params = new URLSearchParams();

		params.append("page", String(currentPage));
		params.append("size", String(itemsPerPage));

		if (filters.dateStart)
			params.append("date_start", filters.dateStart.toISOString());
		if (filters.dateEnd)
			params.append("date_end", filters.dateEnd.toISOString());
		if (filters.title) params.append("title", filters.title);
		if (filters.minScore) params.append("min_score", filters.minScore);
		if (filters.candidateName)
			params.append("candidate_name", filters.candidateName);

		return params.toString();
	};

	const fetchTopMatches = async () => {
		setSearchStatus(true);
		console.log(
			"filters",
			filters,
			"page",
			currentPage,
			"itemsPerPage",
			itemsPerPage
		);

		const queryString = buildQueryString(filters);
		const response = await axios.get(`/top-matches?${queryString}`);
		return response.data;
	};

	const { isLoading, error, data, refetch } = useQuery(
		[
			"top-matches",
			currentPage,
			itemsPerPage,
			filters.dateStart,
			filters.dateEnd,
		],
		fetchTopMatches,
		{
			refetchOnWindowFocus: false,
			// retry: 2,
			enabled: !!activeAccount,
			onSuccess: (data) => {
				setOriginalRecords(data);
				setFilteredRecords(data);
				setSortedData(data);
				setSearchStatus(false);
				console.log("filteredRecords", data);
			},
			onError: (error) => {
				console.error("Error fetching candidate analysis:", error);
				setSearchStatus(false);
				setOriginalRecords(undefined);
				setFilteredRecords(undefined);
			},
		}
	);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			const newFilters: AppliedFilter[] = [];
			(["title", "minScore", "candidateName"] as FilterKeys[]).forEach(
				(field) => {
					const value = filters[field];
					if (value && (field !== "minScore" || value !== "0")) {
						newFilters.push({ field, value });
					}
				}
			);

			setAppliedFilters((prev) => {
				// Remove filters with empty values or minScore of 0 from appliedFilters
				let updatedFilters = prev.filter(
					(tag) =>
						!(tag.field === "title" && !filters.title) &&
						!(tag.field === "candidateName" && !filters.candidateName) &&
						!(
							(tag.field === "minScore" && filters.minScore === "0") ||
							(tag.field === "minScore" && !filters.minScore)
						)
				);

				// Add new filters, ensuring only one filter per field
				newFilters.forEach((newFilter) => {
					const existingIndex = updatedFilters.findIndex(
						(tag) => tag.field === newFilter.field
					);
					if (existingIndex !== -1) {
						updatedFilters[existingIndex].value = newFilter.value;
					} else {
						updatedFilters.push(newFilter);
					}
				});

				return updatedFilters;
			});

			setCurrentPage(1);
			refetch();
		}
	};

	const applyFilter = () => {
		const fields: FilterKeys[] = ["title", "minScore", "candidateName"];
		setAppliedFilters((prev) => {
			// Remove filters with empty values or minScore of 0 from appliedFilters
			let updatedFilters = prev.filter(
				(tag) =>
					!(tag.field === "title" && !filters.title) &&
					!(tag.field === "candidateName" && !filters.candidateName) &&
					!(tag.field === "minScore" && filters.minScore === "0")
			);

			// Add new filters, ensuring only one filter per field
			fields.forEach((field) => {
				const value = filters[field];
				if (value && (field !== "minScore" || value !== "0")) {
					const existingIndex = updatedFilters.findIndex(
						(tag) => tag.field === field
					);
					if (existingIndex !== -1) {
						updatedFilters[existingIndex].value = value;
					} else {
						updatedFilters.push({ field, value });
					}
				}
			});
			return updatedFilters;
		});

		setCurrentPage(1);
		console.log("appliedFilters", appliedFilters);
		refetch();
	};

	const removeTag = (value: string) => {
		setAppliedFilters((prev) => prev.filter((tag) => tag.value !== value));
		setFilters((prev) => {
			const field = appliedFilters.find((tag) => tag.value === value)?.field;
			if (field) {
				return { ...prev, [field]: "" };
			}
			return prev;
		});
		refetch();
	};

	const onItemsPerPageChange = (
		e: SyntheticEvent,
		data: { optionValue: any }
	) => {
		setCurrentPage(1);
		setItemsPerPage(data.optionValue);
		console.log("data.optionValue", data.optionValue);
	};

	// console.log("filterInputForm", filterInputForm);
	// console.log("appliedFilters", appliedFilters);
	// console.log("filteredRecords", filteredRecords);
	// console.log("searchStatus", searchStatus);

	const [sortState, setSortState] = useState<
		Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
	>({
		sortColumn: "score",
		sortDirection: "descending",
	});
	const onSortChange: DataGridProps["onSortChange"] = (e, nextSortState) => {
		setSortState(nextSortState);
	};

	const columns: TableColumnDefinition<TopMatch>[] = [
		createTableColumn<TopMatch>({
			columnId: "candidate_name",
			compare: (a, b) => {
				return (a.name || "").localeCompare(b.name || "");
			},
			renderHeaderCell: () => {
				return "Name";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout
					// media={<PersonRegular />}
					// style={{ paddingLeft: "12px" }}
					// onClick={(e) => handleCandidateClick(e, item.candidateId)}
					>
						<p>{item.name}</p>
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<TopMatch>({
			columnId: "score",
			compare: (a, b) => {
				return a.score - b.score;
			},
			renderHeaderCell: () => {
				return "Assessment Score";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout
						style={{ paddingLeft: "50px" }}
						// style={{ cursor: "pointer" }}
						// onClick={(e) => handleCandidateClick(e, item.candidateId)}
					>
						<p>{item.score}</p>
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<TopMatch>({
			columnId: "assessedOpening",
			compare: (a, b) => {
				return (a.assessedOpening || "").localeCompare(b.assessedOpening || "");
			},
			renderHeaderCell: () => {
				return "Assessed Opening";
			},
			renderCell: (item) => {
				const handleCandidateClick = (e: any, id: number | undefined) => {
					e.stopPropagation();
					window.open(`candidates/dashboard/${id}`, "_blank");
				};
				return (
					<TableCellLayout
						style={{ cursor: "pointer" }}
						onClick={(e) => handleCandidateClick(e, item.candidateId)}
					>
						<Link>{item.assessedOpening}</Link>
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<TopMatch>({
			columnId: "appliedOpening",

			renderHeaderCell: () => {
				return "Opening(s) Applied To";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout style={{ cursor: "pointer" }}>
						{item.appliedOpening.length === 0 ? (
							<div style={{ paddingLeft: "40px" }}>
								<i>N/A</i>
							</div>
						) : (
							<div
								className="top-matched-candidate"
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
									paddingRight: "16px",
								}}
							>
								<span style={{ fontWeight: "600" }}>
									<ul
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: "10px",
										}}
									>
										{item.appliedOpening.map((i: any, index) => {
											return <li key={index}>{i}</li>;
										})}
									</ul>
								</span>
							</div>
						)}
					</TableCellLayout>
				);
			},
		}),
		createTableColumn<TopMatch>({
			columnId: "lastModified",
			compare: (a, b) => {
				return (a.lastModified || "").localeCompare(b.lastModified || "");
			},
			renderHeaderCell: () => {
				return "Last Modified";
			},
			renderCell: (item) => {
				return (
					<TableCellLayout style={{ cursor: "pointer" }}>
						<p>{formattedDate(item.lastModified)}</p>
					</TableCellLayout>
				);
			},
		}),
	];

	const renderOpeningsRow: RowRenderer<TopMatch> = ({ item, rowId }, style) => (
		<DataGridRow<TopMatch>
			key={rowId}
			style={style}
			className={styles.tableRow}
		>
			{({ renderCell }) => (
				<DataGridCell focusMode="group" className={styles.tableCell}>
					{renderCell(item)}
				</DataGridCell>
			)}
		</DataGridRow>
	);

	const { targetDocument } = useFluent();
	const scrollbarWidth = useScrollbarWidth({ targetDocument });

	return (
		<div ref={parentRef} className="top-matches-page-container">
			<div className="top-matches-component-layout">
				<Subtitle1 style={{ height: "32px" }}>Top Matches Report</Subtitle1>
				<div className="top-matches-filter">
					<Accordion
						defaultOpenItems="1"
						collapsible
						className="top-matches-filter-header"
						// className={styles.filterAccordion}
					>
						<AccordionItem value="1">
							<AccordionHeader
								size="small"
								icon={<Filter20Regular />}
								expandIconPosition="end"
								className={styles.filterAccordionHeader}
							>
								<div className={styles.filterAccordionHeaderContent}>
									Filter
									{appliedFilters.length !== 0 && (
										<TagGroup
											onDismiss={(_e, { value }) => removeTag(value)}
											aria-label="Tag group of applied filters"
										>
											{appliedFilters.map((tag, index) => (
												<Tag
													className={styles.tag}
													size="small"
													onClick={(e) => e.stopPropagation()}
													dismissible
													dismissIcon={{ "aria-label": "remove" }}
													value={tag.value}
													key={tag.field}
												>
													{tag.value}
												</Tag>
											))}
										</TagGroup>
									)}
								</div>
							</AccordionHeader>
							<AccordionPanel>
								<Divider />
								<div className="top-matches-filter-container">
									<div
										style={{
											display: "flex",
											columnGap: "10px",
											flexWrap: "wrap",
										}}
									>
										<div>
											<Field
												label="Time Range of Analysis Date"
												className={styles.filterLabel}
											>
												<div className={styles.filterInputDateContainer}>
													<div className={styles.filterInputDate}>
														<div className="date-range-badge">
															<Badge shape="rounded" className={styles.badge}>
																From
															</Badge>
														</div>
														<DatePicker
													
															size="small"
															onSelectDate={(date) => {
																console.log("onSelectDate date", date);
																handleFilterDateChange(date, filters.dateEnd);
															}}
															value={
																filters.dateStart
																	? new Date(filters.dateStart)
																	: undefined
															}
															maxDate={today}
															placeholder="Select Start Date"
															showGoToToday
														/>
													</div>
													<div className={styles.filterInputDate}>
														<div className="date-range-badge">
															<Badge shape="rounded" className={styles.badge}>
																To
															</Badge>
														</div>
														<DatePicker
														
															size="small"
															onSelectDate={(date) => {
																console.log("onSelectDate date", date);
																handleFilterDateChange(filters.dateStart, date);
															}}
															value={
																filters.dateEnd
																	? new Date(filters.dateEnd)
																	: today
															}
															maxDate={today}
															showGoToToday
														/>
													</div>
												</div>
											</Field>
										</div>
										{/* <Divider vertical /> */}

										<div>
											<Field label="Job Title" className={styles.filterLabel}>
												<Input
													size="small"
													className={styles.filterInput}
													contentAfter={<SearchRegular />}
													placeholder="Search by Assessed Job Opening"
													appearance="outline"
													id="KeywordFilter"
													onChange={(e) => {
														setFilters((prev) => ({
															...prev,
															title: e.target.value,
														}));
													}}
													onKeyDown={handleKeyDown}
													value={filters.title}
												/>
											</Field>
										</div>
										{/* <Divider vertical /> */}

										<div>
											<Field
												label="Minimum Score"
												className={styles.filterLabel}
											>
												<Input
													type="number"
													size="small"
													className={styles.filterInput}
													contentAfter={<KeyboardRegular />}
													placeholder="Filter by a Minimum Score"
													appearance="outline"
													onChange={(e) => {
														setFilters((prev) => ({
															...prev,
															minScore: e.target.value,
														}));
													}}
													onKeyDown={handleKeyDown}
													value={filters.minScore}
												/>
											</Field>
										</div>
										{/* <Divider vertical /> */}

										<div>
											<Field
												label="Candidate Name"
												className={styles.filterLabel}
											>
												<Input
													size="small"
													className={styles.filterInput}
													contentAfter={<PersonRegular />}
													placeholder="Search by Candidate Name"
													appearance="outline"
													onChange={(e) => {
														setFilters((prev) => ({
															...prev,
															candidateName: e.target.value,
														}));
													}}
													onKeyDown={handleKeyDown}
													value={filters.candidateName}
												/>
											</Field>
										</div>
									</div>
									<div className={styles.filterActionButtons}>
										<div>
											<Button
												appearance="primary"
												icon={<Filter20Regular />}
												size="small"
												onClick={applyFilter}
											>
												Apply Filters
											</Button>
										</div>
										<div>
											<Button
												icon={<FilterDismissRegular />}
												size="small"
												onClick={clearFilter}
											>
												Clear All Filters
											</Button>
										</div>
									</div>
								</div>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				</div>

				<div>
					<div
						className={
							isLoading ? "top-matches-new-application-toast" : "hidden"
						}
					>
						<ArrowClockwise20Regular style={{ color: "#858585" }} />
						<span
							style={{
								margin: 0,
								color: "#858585",
							}}
						>
							Loading result
						</span>
						<Loading />
					</div>

					<div
						className={
							isLoading ? "hidden" : "top-matches-new-application-toast"
						}
					>
						{filteredRecords && filteredRecords.meta && (
							<>
								<Alert20Filled />
								<span className="toast-contents" style={{ fontSize: "14px" }}>
									There are {filteredRecords.meta.total} matching candidates
									{/* {filterInputForm.time === "Any Time"
									? null
									: `within the ${filterInputForm.time.toLowerCase()}`} */}
									, {""}
									{filteredRecords &&
										filteredRecords.meta.total > 0 &&
										`currently showing ${resultStartRange}–${
											filteredRecords.meta.total >= 10
												? resultEndRange
												: filteredRecords.meta.total
										} of `}
									{filteredRecords ? filteredRecords.meta.total : 0} result(s)
									{/* {selectionArr.length > 0
											? `, ${selectionArr.length} selected `
											: null} */}
								</span>
							</>
						)}
					</div>
				</div>

				<DataGrid
					size="small"
					items={filteredRecords ? filteredRecords.result : []}
					columns={columns}
					focusMode="cell"
					sortable
					sortState={sortState}
					onSortChange={onSortChange}
					// selectionMode="multiselect"
					getRowId={(item) => item.id}
					selectionAppearance="neutral"
					columnSizingOptions={columnSizingOptions}
					// resizableColumns
					style={{
						backgroundColor: "#FFFFFF",
						border: "1px solid #E0E0E0",
						borderRadius: "4px",
						boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.10)",
					}}
				>
					<DataGridHeader className={styles.tableHeader}>
						<DataGridRow>
							{({ renderHeaderCell }) => (
								<DataGridHeaderCell className={styles.tableHeaderCell}>
									{renderHeaderCell()}
								</DataGridHeaderCell>
							)}
						</DataGridRow>
					</DataGridHeader>
					{filteredRecords?.result.length === 0 ? (
						<div
							style={{
								padding: "24px 0",
								display: "flex",
								justifyContent: "center",
								height: `${tableHeight}px`,
							}}
						>
							<Text italic>
								No results found. Please try adjusting your filters or check
								back later.
							</Text>
						</div>
					) : searchStatus ? (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								height: `${tableHeight}px`,
								flexGrow: 1,
								backgroundColor: "#FFFFFF",
								padding: "24px",
								overflowY: "auto",
								position: "relative",
							}}
						>
							<LoadingSpinner />
						</div>
					) : (
						<DataGridBody<TopMatch> itemSize={itemSize} height={tableHeight}>
							{renderOpeningsRow}
						</DataGridBody>
					)}
				</DataGrid>

				<div className="top-matches-pagination-container">
					<PaginationContainer
						candidatesData={filteredRecords}
						searchStatus={true}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						totalItems={filteredRecords ? filteredRecords.meta.total : 0}
						itemsPerPage={itemsPerPage}
						onItemsPerPageChange={onItemsPerPageChange}
						onPageChange={(currentPage: number) => setCurrentPage(currentPage)}
					/>
				</div>
			</div>
		</div>
	);
};

export default TopMatchesReport;
