import React from "react";
import Topbar from "./topbar";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FluentProvider } from "@fluentui/react-components";
import { createDarkTheme, createLightTheme } from "@fluentui/react-components";
import type { BrandVariants, Theme } from "@fluentui/react-components";
import { createTheme, ThemeProvider } from "@fluentui/react";

const Aimbest: BrandVariants = {
	10: "#d8dce9",
	20: "#c1c7da",
	30: "#a9aebc",
	40: "#9397a0",
	50: "#7b7f84",
	60: "#0078d4", // links hover
	70: "#4D5982", // links / button hover
	80: "#212B59", // buttons
	90: "#1E2D4E",
	100: "#1b2546", // original
	110: "#11182d",
	120: "#0e1425",
	130: "#0b101c",
	140: "#a9aebc", // datepicker --colorBrandBackgroundInvertedPressed
	150: "#c1c7da", // datepicker --colorBrandBackgroundInvertedSelected
	160: "#d8dce9", // datepicker --colorBrandBackgroundInvertedHover
};

const lightTheme: Theme = {
	...createLightTheme(Aimbest),
};

const darkTheme: Theme = {
	...createDarkTheme(Aimbest),
};

darkTheme.colorBrandForeground1 = Aimbest[110];
darkTheme.colorBrandForeground2 = Aimbest[120];

const aimbestTheme = createTheme({
	palette: {
		themePrimary: "#1b2546",
		themeLighterAlt: "#d8dce9",
		themeLighter: "#b6bdd4",
		themeLight: "#98a1c0",
		themeTertiary: "#7c87ab",
		themeSecondary: "#636f97",
		themeDarkAlt: "#4d5982",
		themeDark: "#3a466e",
		themeDarker: "#293459",
		neutralLighterAlt: "#faf9f8",
		neutralLighter: "#f3f2f1",
		neutralLight: "#edebe9",
		neutralQuaternaryAlt: "#e1dfdd",
		neutralQuaternary: "#d0d0d0",
		neutralTertiaryAlt: "#c8c6c4",
		neutralTertiary: "#595959",
		neutralSecondary: "#373737",
		neutralSecondaryAlt: "#373737",
		neutralPrimaryAlt: "#2f2f2f",
		neutralPrimary: "#000000",
		neutralDark: "#151515",
		black: "#0b0b0b",
		white: "#ffffff",
	},
});

export default function Layout() {
	const location = useLocation();
	const noTopbar = location.pathname.startsWith("/candidates/dashboard/");

	return (
		<FluentProvider theme={lightTheme}>
			<ThemeProvider applyTo="body" theme={aimbestTheme}>
				<div>
					{!noTopbar && <Topbar />}
					<div className="outlet-wrapper">
						<Outlet />
					</div>
				</div>
			</ThemeProvider>
		</FluentProvider>
	);
}
